import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "components/header/Header.js";
import Steps from "components/features/TwoColWithSteps.js";
import Signup from "components/account/Signup.js";

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Steps />
      <Signup />
      <Footer />
    </AnimationRevealPage>
  );
};
