import styled from "styled-components";
import tw from "twin.macro";
import {
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../header/Header.styles.js";
import Header from "../header/Header.js";
import { NavLinks } from "components/navigationLinks/NavigationLinks.styles.js";

export const TwoColumn = tw.div`tw-px-4 tw-flex tw-justify-between tw-items-center tw-flex-col lg:tw-flex-row tw-h-5/6`;
export const LeftColumn = tw.div`tw-flex tw-flex-col tw-items-center lg:tw-block tw-mt-auto sm:tw-mb-auto tw-mb-[25%]`;
export const Heading = styled.h1`
  ${tw`tw-text-4xl  lg:tw-text-left sm:tw-text-5xl lg:tw-text-6xl xl:tw-text-6xl tw-font-black tw-text-gray-100 tw-leading-none`}
  span {
    ${tw`tw-inline-block tw-mt-2`}
  }
`;
export const HeroContainer = tw.div`tw-z-20 tw-relative tw-px-4 sm:tw-px-8 tw-max-w-screen-xl tw-mx-auto tw-max-h-screen tw-h-full`;
export const HighlightedTextTop = tw.span`tw-bg-gray-900 tw-text-gray-100 tw-px-4 tw-pt-5 tw-pb-8 tw-transform -tw-skew-x-12 tw-inline-block tw-rotate-[2deg] tw-rounded-br-lg tw-rounded-tl-lg tw-text-3xl sm:tw-text-4xl md:tw-text-6xl`;
export const HighlightedTextBottom = tw.span`tw-bg-primary-500 tw-text-gray-100 tw-pl-10 sm:tw-pl-20 tw-pr-4 tw-py-2 tw-transform -tw-skew-x-12 tw-inline-block tw-rotate-[-2deg] tw-translate-y-[-25px] tw-rounded-tr-lg tw-rounded-bl-lg tw-text-3xl sm:tw-text-4xl md:tw-text-6xl`;
export const Hero = tw.section`tw-w-[100vw] tw-h-[100vh] tw-relative tw-overflow-hidden -tw-ml-[2rem] -tw-mt-[2rem] tw-bg-cover tw-bg-center`;
export const Overlay = tw.div`tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-max-h-[100vh] tw-bg-[rgb(50,0,0,0.4)]`;
export const StyledHeader = styled(Header)`
  ${tw`tw-pt-8 tw-max-w-none`}
  ${DesktopNavLinks} ${NavLinks}, ${LogoLink} {
    ${tw`tw-text-gray-100 hover:tw-border-gray-300 hover:tw-text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`tw-text-gray-100 hover:tw-text-primary-500`}
  }
`;
