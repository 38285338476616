import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import illustration from "images/IMG_9355.JPG";
import logo from "images/BADtri_logo_black.svg";
import { Verify } from "api/openApi.ts";
import { PinInput, PinInputField, useToast } from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";
import { ReactComponent as VerifyIcon } from "feather-icons/dist/icons/shield.svg";
import {
  ChakraButton,
  ChakraHStack,
  Container,
  Content,
  Form,
  FormContainer,
  Heading,
  IllustrationContainer,
  IllustrationImage,
  LogoImage,
  LogoLink,
  MainContainer,
  MainContent,
} from "./verify.styles";

export default ({
  logoLinkUrl = "/",
  illustrationImageSrc = illustration,
  headingText = "Verify Your Email",
}) => {
  const toast = useToast();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [verificationCode, setVerificationCode] = React.useState("");

  const verify = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const membershipNumber = urlParams.get("membershipNumber");

    console.log(verificationCode);

    const signUpRequest = {
      membershipNumber: membershipNumber,
      confirmationCode: verificationCode,
    };
    setButtonDisabled(true);

    //TODO - check values are valid

    try {
      await Verify(signUpRequest);
      document.location.href = "/login?membershipNumber=" + membershipNumber;
    } catch (e) {
      console.log(e);
      setButtonDisabled(false);
      toast({
        title: "Hmm not quite try again...",
        status: "error",
        isClosable: true,
      });
    }
  };

  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <ChakraHStack>
                    <PinInput
                      onChange={(e) => {
                        setVerificationCode(e);
                      }}
                    >
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </ChakraHStack>
                  <ChakraButton
                    isLoading={buttonDisabled}
                    spinner={<BeatLoader size={8} color="white" />}
                    colorScheme="red"
                    leftIcon={<VerifyIcon />}
                    onClick={verify}
                    size="lg"
                  >
                    Verify
                  </ChakraButton>
                </Form>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
