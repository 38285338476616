import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  ResponsiveContainer,
} from "recharts";

export default function StackedAreaChart(props) {
  let displayData = [];

  return (
    <ResponsiveContainer>
      <AreaChart
        margin={{ top: 50, right: 0, left: 0, bottom: 50 }}
        data={props.data == undefined ? displayData : props.data}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="male"
          stackId="1"
          stroke="#2986cc"
          fill="#6fa8dc"
        />
        <Area
          type="monotone"
          dataKey="female"
          stackId="1"
          stroke="#c90076"
          fill="#d5a6bd"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
