import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "../../components/header/Header.js";
import People from "../../components/cards/ProfileThreeColGrid.js";

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <People
        subheading="CLUB LEGENDS"
        heading="Coaches and Committee"
        description="We're really lucky at BAD TRI to have some fantastic volunteers! If you join our sessions here are some of the faces you're likely to spot."
      />
      <Footer />
    </AnimationRevealPage>
  );
};
