import React, { useState } from "react";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../header/Header.js";

import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import {
  Actions,
  CloseModalButton,
  Container,
  DecoratorBlob1,
  DecoratorBlob2,
  Heading,
  IllustrationContainer,
  LeftColumn,
  Paragraph,
  RightColumn,
  StyledModal,
  TwoColumn,
  WatchVideoButton,
} from "./ClubChampionshipsHero.styles.js";

export default ({
  heading = "Get ready for the 2024 club championships!",
  watchVideoButtonText = "Watch Video",
  watchVideoYoutubeUrl = "https://content.badtri.org/Club+Championships.mp4",
  imageDecoratorBlob = false,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  return (
    <>
      <Header />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>{heading}</Heading>
            <Paragraph>
              The BADTri club championships have always been about encouraging
              participation, friendly competition and providing races where
              you'll know and recognise other club members.
              <br />
              <br />
              We want our club championships to be open and accessible to all,
              and make sure that anyone taking part has a fair and realistic
              chance of being a contender.
              <br />
              <br />
              Watch the intro video below for a brief introduction into the club
              championships.
            </Paragraph>
            <Actions>
              <WatchVideoButton onClick={toggleModal}>
                <span className="playIconContainer">
                  <PlayIcon className="playIcon" />
                </span>
                <span className="playText">{watchVideoButtonText}</span>
              </WatchVideoButton>
            </Actions>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              {imageDecoratorBlob && <DecoratorBlob2 />}
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
        <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton onClick={toggleModal}>
            <CloseIcon tw="tw-w-6 tw-h-6" />
          </CloseModalButton>
          <div className="content">
            <ResponsiveVideoEmbed url={watchVideoYoutubeUrl} tw="tw-w-full" />
          </div>
        </StyledModal>
      </Container>
    </>
  );
};
