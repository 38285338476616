import React, { useState } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "components/header/Header.js";
import Welcome from "components/account/Welcome.js";
import { GetCookie, ParseJwt, IsAdult } from "api/jwt.js";
import { GetMember } from "api/secureApi.ts";

const HeaderLight = tw(Header)`tw-text-gray-100`;

export default () => {
  const idToken = GetCookie("idToken");
  if (idToken === "") {
    window.location.href = "/login";
  }
  const firstName = ParseJwt(idToken).given_name;
  const membershipNumber = ParseJwt(idToken)["cognito:username"];

  const [memberDetails, setMemberDetails] = useState(null);
  const [adult, setIsAdult] = useState(true);
  const [loading, setIsLoading] = useState(true);

  try {
    if (memberDetails == null) {
      GetMember(idToken, membershipNumber).then((response) => {
        setMemberDetails(response);
        setIsAdult(IsAdult(response.dateOfBirth));
        setIsLoading(false);
      });
    }
  } catch (e) {}

  return (
    <AnimationRevealPage>
      <>
        <HeaderLight />
        <Welcome
          memberDetails={memberDetails}
          setMemberDetails={setMemberDetails}
          loading={loading}
          firstName={firstName}
          isAdult={adult}
          membershipNumber={membershipNumber}
        />
      </>
      <Footer />
    </AnimationRevealPage>
  );
};
