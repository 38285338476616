import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Link,
} from "@chakra-ui/react";

const Video = tw.video`tw-mt-4 tw-mr-auto tw-ml-auto`;
const ChakraAccordionItem = tw(AccordionItem)``;
const Subheading = tw(SubheadingBase)`tw-mb-4 tw-text-center`;
const Heading = tw(SectionHeading)`tw-w-full`;
const Description = tw(SectionDescription)`tw-w-full tw-text-center`;
const ChakraTitleBox = tw(
  Box,
)`tw-w-full tw-text-left tw-font-bold tw-text-xl tw-py-3`;
const Column = tw.div`tw-flex tw-flex-col tw-items-center`;
const HeaderContent = tw.div``;
const ChakraAccordion = tw(Accordion)`tw-w-full tw-border-gray-500`;
const FAQSContainer = tw.div`tw-mt-12 tw-max-w-4xl tw-relative tw-w-full`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`tw-pointer-events-none -tw-z-20 tw-absolute tw-right-0 tw-top-0 tw-h-56 tw-w-56 tw-opacity-15 tw-transform tw-translate-x-2/3 -tw-translate-y-12 tw-text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`tw-pointer-events-none -tw-z-20 tw-absolute tw-left-0 tw-bottom-0 tw-h-64 tw-w-64 tw-opacity-15 tw-transform -tw-translate-x-2/3 tw-text-primary-500`}
`;

export default ({
  subheading = "FAQS",
  heading = "You have Questions ?",
  description = "We're thrilled that you're reaching out to BAD Tri Triathlon Club! Whether you're an aspiring triathlete, a seasoned pro, or someone interested in our events and club, we're here to assist you. Feel free to use the contact details below to get in touch, and we'll get back to you as soon as possible.",
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            <ChakraAccordion>
              <ChakraAccordionItem>
                <h2>
                  <AccordionButton>
                    <ChakraTitleBox as="span" flex="1" textAlign="left">
                      I cannot find my address in the address lookup
                    </ChakraTitleBox>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  In this case, please select a nearby address which you can
                  find. We won't send you anything, we only use this data to
                  assess the location of our members so we can try locate
                  sessions appropriately.
                </AccordionPanel>
              </ChakraAccordionItem>

              <ChakraAccordionItem>
                <h2>
                  <AccordionButton>
                    <ChakraTitleBox as="span" flex="1" textAlign="left">
                      I am not a member, but I'd like to come to a 'taster'
                      session. Is this possible?
                    </ChakraTitleBox>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <>
                    Yes. You will need to complete a day membership form in
                    order to attend.
                  </>
                  <> </>
                  <Link
                    textDecoration={"underline"}
                    target="_blank"
                    href="/day-membership"
                  >
                    Please find it here
                  </Link>
                  <>
                    . Please give an indication of your preferred session. Our
                    membership secretary will confirm your attendance (subject
                    to availability). Alternatively contact us below for more
                    information.
                  </>
                </AccordionPanel>
              </ChakraAccordionItem>

              <ChakraAccordionItem>
                <h2>
                  <AccordionButton>
                    <ChakraTitleBox as="span" flex="1" textAlign="left">
                      I'm new. How do I book onto a session?
                    </ChakraTitleBox>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  You need to download the app 'Spond'. Once you've downloaded
                  the app, login to your BADTri account, and you should be
                  presented with a button to join your eligible Spond groups.
                  Click the button, and you'll be redirected to the app where
                  you can request to join the group. A committee member will
                  then admit you. You can then view all of our sessions and book
                  onto them.
                </AccordionPanel>
              </ChakraAccordionItem>

              <ChakraAccordionItem>
                <h2>
                  <AccordionButton>
                    <ChakraTitleBox as="span" flex="1" textAlign="left">
                      How do I use spond for both myself and my children?
                    </ChakraTitleBox>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  Once you have created a BAD Tri account for yourself and your
                  children and you have downloaded Spond on your mobile, go
                  ahead and join spond yourself (the seniors group) and then
                  watch the below video to see how you add your children too.
                  <Video width="320" height="240" controls>
                    <source src="./spondjoiningvideo.mp4" type="video/mp4" />
                  </Video>
                </AccordionPanel>
              </ChakraAccordionItem>
            </ChakraAccordion>
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
