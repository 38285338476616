import tw from "twin.macro";
import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "components/header/Header.js";
import SessionsSlider from "components/sessionsSlider/SessionsSlider";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionHeading } from "components/misc/Headings";

export default () => {
  const Heading = tw(SectionHeading)`tw-text-left tw-pt-10`;
  const Paragraph = tw.p`tw-text-left tw-text-sm lg:tw-text-base tw-font-medium tw-text-gray-600`;
  const SessionsPadding = tw(ContentWithPaddingXl)`tw-pt-0 `;

  return (
    <AnimationRevealPage>
      <Header />
      <ContentWithPaddingXl>
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/profile">My Account</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink>Edit Sessions</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Heading tw="tw-text-left">Edit Sessions</Heading>
        <Paragraph>
          Session edits will go live immediately. Swipe left and right to scroll
          between the days.
        </Paragraph>
      </ContentWithPaddingXl>
      <SessionsPadding>
        <SessionsSlider isEditable={true} />
      </SessionsPadding>
      <Footer />
    </AnimationRevealPage>
  );
};
