import { GetAddresses } from "api/openApi.ts";

const { useState } = require("react");

const useGetAddresses = () => {
  const [addresses, setAddresses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const setAddressQuery = async (addressQuery) => {
    setIsLoading(true);
    const addresses = await GetAddresses(addressQuery);
    if (addresses) {
      setAddresses(addresses);
    }

    setIsLoading(false);
  };

  return { addresses, isLoading, setAddressQuery };
};

export default useGetAddresses;
