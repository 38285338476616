import React from "react";
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";
import logo from "../../images/BADtri_logo_black.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { GetCookie } from "api/jwt.js";
import {
  loggedInNavigationLinks,
  navigationLinks,
} from "components/navigationLinks/NavigationLinks.js";
import {
  DesktopNavLinks,
  Header,
  LogoLink,
  MobileNavLinks,
  MobileNavLinksContainer,
  NavToggle,
  collapseBreakPointCssMap,
} from "./Header.styles.js";
import { css } from "styled-components/macro"; //eslint-disable-line

export default ({ logoLink, className, collapseBreakpointClass = "lg" }) => {
  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img style={{ width: "7rem" }} src={logo} alt="logo" />
    </LogoLink>
  );

  const idToken = GetCookie("idToken");
  const isLoggedIn = idToken !== "";

  logoLink = logoLink || defaultLogoLink;
  const links = isLoggedIn ? loggedInNavigationLinks : navigationLinks;

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        {logoLink}
        <MobileNavLinks
          initial={{ x: "150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {links}
        </MobileNavLinks>
        <NavToggle
          aria-label="Menu"
          onClick={toggleNavbar}
          className={showNavLinks ? "open" : "closed"}
        >
          {showNavLinks ? (
            <CloseIcon tw="tw-w-6 tw-h-6" />
          ) : (
            <MenuIcon tw="tw-w-6 tw-h-6" />
          )}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};
