import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import Header from "components/header/Header.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import JuniorsDescription from "components/features/JuniorsDescription.js";

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <JuniorsDescription></JuniorsDescription>
      <Footer />
    </AnimationRevealPage>
  );
};
