import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "../../components/header/Header.js";
import Documents from "../../components/forms/DocumentsList.js";

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Documents />
      <Footer />
    </AnimationRevealPage>
  );
};
