import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "components/header/Header.js";
import Reports from "components/account/privilegedViews/Reports.js";

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Reports></Reports>
      <Footer />
    </AnimationRevealPage>
  );
};
