import { deleteTokens } from "api/jwt";
import { NavLink, NavLinks, PrimaryLink } from "./NavigationLinks.styles";

export const navigationLinks = [
  <NavLinks key={1}>
    <NavLink href="/sessions">Sessions</NavLink>
    <NavLink href="/juniors">Junior Section</NavLink>
    <NavLink href="/contact-us">Contact Us</NavLink>
    <NavLink href="/championships">Club Championships</NavLink>
    <NavLink href="/login">Sign In</NavLink>
    <PrimaryLink href="/join">JOIN US</PrimaryLink>
  </NavLinks>,
];

export const loggedInNavigationLinks = [
  <NavLinks>
    <NavLink href="/sessions">Sessions</NavLink>
    <NavLink href="/juniors">Junior Section</NavLink>
    <NavLink href="/contact-us">Contact Us</NavLink>
    <NavLink href="/championships">Club Championships</NavLink>
    <NavLink onClick={(e) => deleteTokens()} href="/login">
      Sign Out
    </NavLink>
    <PrimaryLink href="/profile">MY ACCOUNT</PrimaryLink>
  </NavLinks>,
];
