import tw from "twin.macro";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";

export const Row = tw.div`tw-flex`;
export const NavLink = tw.a`tw-mt-4 lg:tw-mt-0 tw-transition tw-duration-300 tw-font-medium tw-pb-1 tw-border-b-2 lg:tw-mr-12 last:tw-mr-0 tw-text-gray-700 tw-border-gray-400 hocus:tw-border-gray-700 `;
export const HeroRow = tw(
  Row,
)`tw-max-w-xl tw-flex-col tw-justify-between tw-items-center tw-py-20 lg:tw-py-24 tw-mx-auto`;

export const Heading = tw(
  HeadingBase,
)`tw-text-center tw-text-primary-900 tw-leading-snug`;
export const Description = tw(
  DescriptionBase,
)`tw-mt-12 tw-text-center lg:tw-text-base tw-text-gray-700 tw-max-w-lg tw-mx-auto lg:tw-mx-0`;
