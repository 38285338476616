import React, { useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { ContentWithPaddingXl, Container } from "components/misc/Layouts";
import { GetPublicStats } from "api/openApi.ts";
import CountUp from "react-countup";
import { Button } from "@chakra-ui/react";

const PrimaryBackgroundContainer = tw.div`tw-py-5 lg:tw-py-16 tw-bg-red-200 tw-rounded-lg tw-relative`;
const Row = tw.div`tw-px-4 sm:tw-px-16 tw-mx-auto tw-flex tw-justify-center tw-items-center tw-relative tw-z-10 tw-flex-col lg:tw-flex-row tw-text-center lg:tw-text-left`;

const ColumnContainer = tw.div`lg:tw-w-1/2 tw-max-w-lg`;
const TextContainer = tw(
  ColumnContainer,
)`tw-text-2xl sm:tw-text-4xl tw-font-bold`;
const Subheading = tw.h6`tw-text-primary-500 tw-opacity-75`;
const Heading = tw.h5`tw-text-gray-900`;

const LinksContainer = tw(
  ColumnContainer,
)`tw-flex tw-justify-start tw-mt-6 tw-flex-col sm:tw-flex-row tw-pl-0 lg:tw-mt-2`;
const StatisticContainer = tw.div`tw-rounded-3xl  tw-text-left`;
const StatisticContainerInner = tw.div`tw-mr-10`;
const StatisticHeading = tw(
  CountUp,
)`tw-font-bold tw-text-4xl tw-text-gray-800  tw-text-left`;
const StatisticDescription = tw.h3`tw-text-gray-700 tw-font-light tw-text-left`;
const StatisticHeadingSkeleton = tw(
  StatisticHeading,
)`tw-animate-pulse tw-p-6  tw-text-left`;

const LeftColumn = tw.div`tw-w-full`;
const DecoratorBlobContainer = tw.div`tw-absolute tw-inset-0 tw-overflow-hidden tw-rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1,
)`tw-absolute tw-bottom-0 tw-left-0 tw-w-80 tw-h-80 tw-transform -tw-translate-x-20 tw-translate-y-32 tw-text-primary-500 tw-opacity-10`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1,
)`tw-absolute tw-top-0 tw-right-0 tw-w-80 tw-h-80 tw-transform  tw-translate-x-20 -tw-translate-y-64 tw-text-primary-500 tw-opacity-15`;
export default ({
  subheading = "",
  heading = "Want to be the next member?",
  primaryLinkText = "JOIN US",
  primaryLinkUrl = "/join",
  secondaryLinkText = "CONTACT US",
  secondaryLinkUrl = "/contact-us",
  pushDownFooter = true,
}) => {
  const [loading, setIsLoading] = useState(true);
  const [numberOfJuniors, setNumberOfJuniors] = useState(0);
  const [numberOfAdults, setNumberOfAdults] = useState(0);

  GetPublicStats()
    .then((response) => {
      setNumberOfAdults(response.sumOfAdults);
      setNumberOfJuniors(response.sumOfJuniors);
      setIsLoading(false);
    })
    .catch((error) => {
      console.log(error);
    });

  return (
    <Container>
      <ContentWithPaddingXl tw="tw-my-0 tw-py-0 md:tw-px-10">
        <PrimaryBackgroundContainer>
          <Row>
            <StatisticContainer tw="tw-mb-2 tw-flex tw-px-10 lg:tw-px-0 tw-justify-center lg:tw-justify-start tw-w-full">
              <StatisticContainerInner>
                {!loading && (
                  <StatisticHeading
                    enableScrollSpy={true}
                    end={numberOfAdults}
                  ></StatisticHeading>
                )}
                <StatisticDescription>Adult Members</StatisticDescription>
              </StatisticContainerInner>

              <StatisticContainerInner tw="tw-mr-0">
                {!loading && (
                  <StatisticHeading
                    enableScrollSpy={true}
                    end={numberOfJuniors}
                  ></StatisticHeading>
                )}
                <StatisticDescription>Junior Members</StatisticDescription>
              </StatisticContainerInner>
            </StatisticContainer>
            <LeftColumn tw="tw-hidden lg:tw-block">
              <StatisticContainer>
                <TextContainer tw="tw-w-full">
                  {subheading && <Subheading>{subheading}</Subheading>}
                  <Heading>{heading}</Heading>
                </TextContainer>
              </StatisticContainer>
              <LinksContainer variant="solid" tw="tw-w-full">
                <Button
                  tw="tw-mr-4 tw-mb-4"
                  size={"lg"}
                  colorScheme="red"
                  onClick={(e) => {
                    document.location.href = primaryLinkUrl;
                  }}
                >
                  {primaryLinkText}
                </Button>
                <Button
                  tw="tw-mr-4 tw-mb-4"
                  size={"lg"}
                  variant="outline"
                  colorScheme="red"
                  onClick={(e) => {
                    document.location.href = secondaryLinkUrl;
                  }}
                >
                  {secondaryLinkText}
                </Button>
              </LinksContainer>
            </LeftColumn>
          </Row>
          <DecoratorBlobContainer>
            <DecoratorBlob1 />
            <DecoratorBlob2 />
          </DecoratorBlobContainer>
        </PrimaryBackgroundContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
