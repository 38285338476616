import tw from "twin.macro";

const Dots = tw.span`tw-w-1 tw-h-1 first:tw-bg-gray-500 tw-bg-gray-200 tw-rounded-full first:tw-ml-auto last:tw-mr-auto`;

export const getSessionSliderSettings = (isEditable) => {
  return {
    infinite: true,
    arrows: false,
    dots: true,
    slidesToShow: 3,
    autoplay: !isEditable,
    autoplaySpeed: 6000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div style={{ textAlign: "center" }}>
        <ul style={{ display: "inline-flex", gap: "5px" }}> {dots} </ul>
      </div>
    ),
    customPaging: () => (
      <Dots
        style={{
          display: "inline-flex",
          flexDirection: "col",
          fontSize: "0",
        }}
      ></Dots>
    ),
  };
};
