import React from "react";
import {
  Heading,
  Hero,
  HeroContainer,
  HighlightedTextBottom,
  HighlightedTextTop,
  LeftColumn,
  Overlay,
  StyledHeader,
  TwoColumn,
} from "./VideoHero.styles.js";
import { navigationLinks } from "components/navigationLinks/NavigationLinks.js";

const VideoHero = () => {
  return (
    <Hero>
      <video
        style={{ minHeight: "100%", minWidth: "100%", objectFit: "cover" }}
        src="https://content.badtri.org/hero-video-v1.mp4"
        type="video/mp4"
        autoPlay
        muted
        loop
        playsInline
      ></video>
      <Overlay>
        <HeroContainer>
          <StyledHeader />
          <TwoColumn>
            <LeftColumn>
              <Heading>
                <HighlightedTextTop>Bristol & District</HighlightedTextTop>
                <br />
                <HighlightedTextBottom>Triathletes</HighlightedTextBottom>
              </Heading>
            </LeftColumn>
          </TwoColumn>
        </HeroContainer>
      </Overlay>
    </Hero>
  );
};

export default VideoHero;
