import React from "react";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-8.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-7.svg";

const ContentWithPadding = tw(ContentWithPaddingXl)``;
const HeadingContainer = tw.div`tw-text-left`;
const Subheading = tw(SubheadingBase)`tw-mb-4`;
const Heading = tw(SectionHeading)`tw-text-left`;
const ImageGalleryStyled = tw(ImageGallery)`tw-mb-20`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`tw-pointer-events-none -tw-z-20 tw-absolute tw-left-0 tw-bottom-0 tw-h-64 tw-w-64 tw-opacity-15 tw-transform -tw-translate-x-2/3 tw-text-primary-500`}
`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`tw-pointer-events-none -tw-z-20 tw-absolute tw-right-0 tw-top-0 tw-h-56 tw-w-56 tw-opacity-15 tw-transform tw-translate-x-2/3 -tw-translate-y-12 tw-text-teal-400`}
`;
const Description = tw.blockquote`tw-text-left tw-text-sm sm:tw-text-lg lg:tw-text-lg tw-mt-5 tw-mb-5 md:tw-mb-12`;

export default ({
  subheading = "",
  heading = "Junior Section",
  description = "",
}) => {
  const images = [
    {
      original: "/Juniors/juniors_1.jpeg",
      thumbnail: "/Juniors/juniors_1.jpeg",
    },
  ];

  return (
    <Container>
      <ContentWithPadding>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <Description>
          BADTri is home to the largest junior triathlon club in Bristol. The
          Junior section covers athletes from ages 8 to 18. We're here to help
          young athletes get a first taste of swim, bike, run and hopefully try
          some racing. Our number one aim is to have fun and get children
          involved in the sport. We run a weekly two hour training session at
          Henbury leisure centre, from 2:30 to 4:30, where the 1st hour is
          either a run or a bike session and the second hour is a swim set.
          <br />
          <br />
          We cater for all abilities, the only requirement is that juniors can
          swim 100m before they join the club. We're happy for new, potential
          members, to come along and have some try out sessions before joining
          the club.
          <br />
          <br />
          We also run a development squad for our more competitive, older
          athletes. This is focused on 14 to 18 years olds looking to race in
          either the BTF regional or National series. This group have a two hour
          swim set on a Saturday so we can focus on longer, more endurance work,
          they also ride as a group on a Sunday morning. We have a number of
          regional and national squad athletes within the development group with
          a number of our former athletes now in BTF development squads.
          <br />
          <br />
          We have a small, enthusiastic, group of coaches who focus on junior
          development. A number of our coaches also support the regional academy
          giving them additional experience and a strong link with the BTF
          development pathway.
        </Description>
        <ImageGalleryStyled items={images} />
      </ContentWithPadding>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
