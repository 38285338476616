import React from "react";
import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings";

const Container = tw.div`tw-relative sm:tw-w-auto sm:-tw-translate-x-0 tw-w-screen -tw-translate-x-8`;
const Content = tw.div`tw-max-w-screen-xl tw-mx-auto tw-py-16 lg:tw-py-20 lg:tw-px-12`;

const HeadingWithControl = tw.div` tw-px-8 sm:tw-px-0`;
const Heading = tw(SectionHeading)`tw-text-left`;
const DocumentSection = tw.div` tw-px-8 sm:tw-px-0`;
const DocumentSectionTitle = tw(
  SectionHeading,
)`tw-text-left tw-text-2xl tw-mt-10`;

const DocumentContainer = tw.div`tw-bg-gray-200 tw-w-full lg:tw-inline-flex tw-p-5 tw-mt-2 tw-p-2 tw-rounded`;
const DocumentTitle = tw.div`lg:tw-inline-block tw-font-bold tw-mr-3 tw-p-2 lg:tw-w-2/5 tw-w-full`;
const DocumentDescription = tw.div`lg:tw-inline-block tw-p-2 tw-w-full tw-mb-2 lg:tw-mb-0`;
const Download = tw.a`lg:tw-float-right lg:tw-ml-0 tw-ml-2 tw-bg-primary-400 hover:tw-bg-primary-600 tw-duration-200 tw-text-gray-100 tw-p-2 tw-font-medium tw-rounded tw-mt-auto tw-mb-auto`;

const Paragraph = tw.p`tw-my-5 lg:tw-my-8 tw-text-sm lg:tw-text-base tw-font-medium tw-text-gray-600 tw-max-w-lg tw-mx-auto lg:tw-mx-0 tw-max-w-none`;

export default () => {
  const documents = [
    {
      sectionTitle: "Constitution",
      sectionDocuments: [
        {
          DocumentTitle: "BADTri Constitution",
          DocumentDescription: "The document that governs our club",
          DocumentLink:
            "/files/Constitution/BADTri Constitution 2023-12-01.pdf",
        },
      ],
    },
    {
      sectionTitle: "Policies",
      sectionDocuments: [
        {
          DocumentTitle: "BADTri Anti-Doping Policy",
          DocumentDescription: "Our anti-doping policy",
          DocumentLink:
            "/files/Policies/BADTri Anti-Doping Policy 2023-12-02.pdf",
        },
        {
          DocumentTitle: "BADTri Data Privacy Notice",
          DocumentDescription: "Our policy on how we handle your data",
          DocumentLink:
            "/files/Policies/BADTri Data Privacy Notice 2023-12-01.pdf",
        },
        {
          DocumentTitle: "BADTri Equity Policy",
          DocumentDescription: "Our equity policy",
          DocumentLink: "/files/Policies/BADTri Equity Policy 2023-12-02.pdf",
        },
        {
          DocumentTitle: "BADTri Photography Policy",
          DocumentDescription:
            "Our photography policy, especially at junior events",
          DocumentLink:
            "/files/Policies/BADTri Photography Policy 2023-12-01.pdf",
        },
        {
          DocumentTitle: "BADTri Junior Data Privacy Notice",
          DocumentDescription:
            "Our policy on how we handle your data, worded in a simplified form suitable for our junior members",
          DocumentLink:
            "/files/Policies/BADTri Junior Data Privacy Notice 2018-12-01.pdf",
        },
        {
          DocumentTitle: "BADTri Online Safety and Social Media Policy",
          DocumentDescription: "Our online safety and social media policy.",
          DocumentLink:
            "/files/Policies/BADTri Online Safety and Social Media Policy 2023-12-01.pdf",
        },
        {
          DocumentTitle: "BADTri Photography Policy",
          DocumentDescription:
            "Our photography policy, especially at junior events.",
          DocumentLink:
            "/files/Policies/BADTri Photography Policy 2023-12-01.pdf",
        },
      ],
    },
    {
      sectionTitle: "Code of Conduct",
      sectionDocuments: [
        {
          DocumentTitle: "BadTri Junior Code of Conduct for Children",
          DocumentDescription:
            "A code of conduct addressed at our junior members",
          DocumentLink:
            "/files/Code of Conduct/BADTri Junior Code of Conduct for Children 2023-12-01.pdf",
        },
        {
          DocumentTitle: "BadTri Junior Code of Conduct for Parents",
          DocumentDescription:
            "A code of conduct addressed at parents of our junior members",
          DocumentLink:
            "/files/Code of Conduct/BADTri Junior Code of Conduct for Parents 2023-12-01.pdf",
        },
        {
          DocumentTitle:
            "BTF Code of Conduct for Club Officials and Volunteers",
          DocumentDescription:
            "A code of conduct for our club officials and volunteers",
          DocumentLink:
            "/files/Code of Conduct/BTF Code of Conduct for Club Officials and Volunteers 2023-12-01.pdf",
        },
      ],
    },
    {
      sectionTitle: "Forms",
      sectionDocuments: [
        {
          DocumentTitle: "Accident Report Form",
          DocumentDescription:
            "To be completed in the event of an accident during a club session.",
          DocumentLink:
            "/files/Forms/BADTri Accident Report Form 2023-12-01.pdf",
        },
        {
          DocumentTitle: "Day Membership Form",
          DocumentDescription:
            "To be completed by prospective members if they wish to attend a BADTri session on a trial basis.",
          DocumentLink:
            "/files/Forms/BADTri Day Membership Form 2023-12-01.pdf",
        },
        {
          DocumentTitle: "Photography Registration Form",
          DocumentDescription:
            "Use this form to register your intention to use photography equipment at a BADTri Junior session or event.",
          DocumentLink:
            "/files/Forms/Photography Registration Form 2023-12-01.pdf",
        },
        {
          DocumentTitle:
            "Internet and Social Media - Statement of Acceptable Use Form",
          DocumentDescription: "Sign this form when joining BADTri Juniors.",
          DocumentLink:
            "/files/Forms/Statement of Acceptable Use of Internet and Social Media 2023-12-01.pdf",
        },
      ],
    },
    {
      sectionTitle: "Guides",
      sectionDocuments: [
        {
          DocumentTitle: "Parents Guide - Child Protection and Safeguarding",
          DocumentDescription:
            "A child protection guide addressed at parents of junior events.",
          DocumentLink:
            "/files/Guides/BADTri Child Protection and Safeguarding – A Guide for Parents 2023-12-01.pdf",
        },
        {
          DocumentTitle: "Junior Guide - Child Protection and Help",
          DocumentDescription:
            "A young persons' guide to child protection and options open to them for help.",
          DocumentLink:
            "/files/Guides/BADTri Junior Young Persons Guide 2023-12-01.pdf",
        },
      ],
    },
    {
      sectionTitle: "Newsletters",
      sectionDocuments: [
        {
          DocumentTitle: "Chairman's Chatter",
          DocumentDescription:
            "December 2023 - A summary of the month from the BADTri chair, Hans-Peter.",
          DocumentLink:
            "/files/Newsletters/Chairman's Chatter - December 2023.pdf",
        },
        {
          DocumentTitle: "Chairman's Chatter",
          DocumentDescription:
            "November 2023 - A summary of the month from the BADTri chair, Hans-Peter.",
          DocumentLink:
            "/files/Newsletters/Chairman's Chatter - November 2023.pdf",
        },
        {
          DocumentTitle: "Chairman's Chatter",
          DocumentDescription:
            "October 2023 - A summary of the month from the BADTri chair, Hans-Peter.",
          DocumentLink:
            "/files/Newsletters/Chairman's Chatter - October 2023.pdf",
        },
        {
          DocumentTitle: "Chairman's Chatter",
          DocumentDescription:
            "September 2023 - A summary of the month from the BADTri chair, Hans-Peter.",
          DocumentLink:
            "/files/Newsletters/Chairman's Chatter - September 2023.pdf",
        },
        {
          DocumentTitle: "Chairman's Chatter",
          DocumentDescription:
            "July 2023 - A summary of the month from the BADTri chair, Hans-Peter.",
          DocumentLink: "/files/Newsletters/Chairman's Chatter - July 2023.pdf",
        },
        {
          DocumentTitle: "Chairman's Chatter",
          DocumentDescription:
            "June 2023 - A summary of the month from the BADTri chair, Hans-Peter.",
          DocumentLink: "/files/Newsletters/Chairman's Chatter - June 2023.pdf",
        },
        {
          DocumentTitle: "Chairman's Chatter",
          DocumentDescription:
            "May 2023 - A summary of the month from the BADTri chair, Hans-Peter.",
          DocumentLink: "/files/Newsletters/Chairman's Chatter - May 2023.pdf",
        },
        {
          DocumentTitle: "Chairman's Chatter",
          DocumentDescription:
            "April 2023 - A summary of the month from the BADTri chair, Hans-Peter.",
          DocumentLink: "/files/Newsletters/Chairman's Corner - April 2023.pdf",
        },
        {
          DocumentTitle: "Chairman's Chatter",
          DocumentDescription:
            "February 2023 - A summary of the month from the BADTri chair, Hans-Peter.",
          DocumentLink:
            "/files/Newsletters/Chairman's Chatter - February 2023.pdf",
        },
      ],
    },
    {
      sectionTitle: "AGMs",
      sectionDocuments: [
        {
          DocumentTitle: "BADTri AGM 2023",
          DocumentDescription: "2023 AGM Presentation Slides.",
          DocumentLink: "/files/AGMs/BADTri AGM 25 May 2023.ppsx",
        },
        {
          DocumentTitle: "BADTri AGM 2024",
          DocumentDescription: "2024 AGM Presentation Slides.",
          DocumentLink: "/files/AGMs/BADTri AGM 13 June 2024.ppsx",
        },
      ],
    },
    {
      sectionTitle: "Accounts",
      sectionDocuments: [
        {
          DocumentTitle: "BADTri Accounts 2022",
          DocumentDescription:
            "BADTri Accounts for the year ending 31st December 2022",
          DocumentLink: "/files/Accounts/Bad Tri Accounts 2022-12-31.pdf",
        },
        {
          DocumentTitle: "BADTri Accounts 2023",
          DocumentDescription:
            "BADTri Accounts for the year ending 31st December 2023",
          DocumentLink: "/files/Accounts/Bad Tri 2023 Accounts.pdf",
        },
      ],
    },
  ];

  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>Club Documents</Heading>
          <Paragraph>Find below all the important club documents.</Paragraph>
        </HeadingWithControl>
        <hr></hr>
        {documents.map((documentSection, index) => (
          <DocumentSection>
            <DocumentSectionTitle>
              {documentSection.sectionTitle}
            </DocumentSectionTitle>
            {documentSection.sectionDocuments.map((documentDetails, index) => (
              <DocumentContainer>
                <DocumentTitle>{documentDetails.DocumentTitle}</DocumentTitle>
                <DocumentDescription>
                  {documentDetails.DocumentDescription}
                </DocumentDescription>
                <Download
                  href={`https://content.badtri.org${documentDetails.DocumentLink}`}
                  target="_blank"
                >
                  Download
                </Download>
              </DocumentContainer>
            ))}
          </DocumentSection>
        ))}
      </Content>
    </Container>
  );
};
