import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import SimpleContactUs from "components/forms/SimpleContactUs";
import Header from "../../components/header/Header.js";
import SingleCol from "components/faqs/SingleCol";

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <SingleCol />
      <SimpleContactUs />
      <Footer />
    </AnimationRevealPage>
  );
};
