import React, { useRef, useEffect, useState } from "react";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import StackedAreaChart from "./graphs/StackedAreaChart.tsx";
import StackedAgeAreaChart from "./graphs/StackedAgeAreaChart.tsx";
import { ResponsiveContainer } from "recharts";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Skeleton,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { GetCookie } from "api/jwt";
import { GetAllMembers, GetPrivateStatistics } from "api/secureApi.ts";
mapboxgl.accessToken =
  "pk.eyJ1IjoibWFya2Fsc3RvbiIsImEiOiJjangzYWt0ZHYwMTdzNDlrdDhtMHl0a3MyIn0.LWzTJORvF1PLlosFIAEyjQ";

const TileContainer = tw.div`tw-grid md:tw-grid-cols-3 tw-gap-5 tw-mt-8 tw-mb-16`;
const SectionHeadingLeft = tw(
  SectionHeading,
)`tw-text-left tw-text-4xl tw-mt-10`;
const SubHeadingHeadingLeft = tw(
  SectionHeading,
)`tw-text-left tw-text-xl tw-mt-12 tw-font-semibold`;
const Tile = tw.div`tw-shadow-2xl tw-border-l-8 tw-border-primary-800 tw-bg-gray-100`;
const StatisticContainer = tw.div` tw-p-8 tw-rounded-3xl`;
const StatisticHeading = tw.h2`tw-font-bold tw-text-4xl tw-text-gray-700 tw-w-fit`;
const StatisticDescription = tw.h3`tw-text-gray-600 tw-font-light`;
//const ResponsiveContainer = tw.div`tw-w-[400px] tw-h-[400px]`
const ChackraTabs = tw(Tabs)`tw-mt-12`;
const MapContainer = tw.div`tw-h-[500px] tw-my-8 tw-w-full`;

export default ({}) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-2.59);
  const [lat, setLat] = useState(51.465);
  const [zoom, setZoom] = useState(10.7);
  const [stats, setStats] = useState(null);
  const [membersLoading, setMembersLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [percentageActive, setPercentageActive] = useState(0);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    const newMap = (map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/markalston/clodjseu5000q01pc75ur959e",
      center: [lng, lat],
      zoom: zoom,
    }));
    newMap.on("load", () => {
      // Add a geojson point source.
      // Heatmap layers also work with a vector tile source.

      const idToken = GetCookie("idToken");

      GetPrivateStatistics(idToken)
        .then((response) => {
          console.log(response);
          setStats(response);
          newMap.addSource("earthquakes", {
            type: "geojson",
            data: response.geojson,
          });
          newMap.addLayer(
            {
              id: "earthquakes-heat",
              type: "heatmap",
              source: "earthquakes",
              maxzoom: 9,
              paint: {
                // Increase the heatmap weight based on frequency and property magnitude
                "heatmap-weight": [
                  "interpolate",
                  ["linear"],
                  ["get", "mag"],
                  0,
                  0,
                  6,
                  1,
                ],
                // Increase the heatmap color weight weight by zoom level
                // heatmap-intensity is a multiplier on top of heatmap-weight
                "heatmap-intensity": [
                  "interpolate",
                  ["linear"],
                  ["zoom"],
                  0,
                  1,
                  9,
                  3,
                ],
                // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                // Begin color ramp at 0-stop with a 0-transparancy color
                // to create a blur-like effect.
                "heatmap-color": [
                  "interpolate",
                  ["linear"],
                  ["heatmap-density"],
                  0,
                  "rgba(33,102,172,0)",
                  0.2,
                  "rgb(103,169,207)",
                  0.4,
                  "rgb(209,229,240)",
                  0.6,
                  "rgb(253,219,199)",
                  0.8,
                  "rgb(239,138,98)",
                  1,
                  "rgb(178,24,43)",
                ],
                // Adjust the heatmap radius by zoom level
                "heatmap-radius": [
                  "interpolate",
                  ["linear"],
                  ["zoom"],
                  0,
                  2,
                  9,
                  20,
                ],
                // Transition from heatmap to circle layer by zoom level
                "heatmap-opacity": [
                  "interpolate",
                  ["linear"],
                  ["zoom"],
                  7,
                  1,
                  9,
                  0,
                ],
              },
            },
            "waterway-label",
          );

          newMap.addLayer(
            {
              id: "earthquakes-point",
              type: "circle",
              source: "earthquakes",
              minzoom: 7,
              paint: {
                // Size circle radius by earthquake magnitude and zoom level
                "circle-radius": [
                  "interpolate",
                  ["linear"],
                  ["zoom"],
                  7,
                  ["interpolate", ["linear"], ["get", "mag"], 1, 1, 6, 4],
                  16,
                  ["interpolate", ["linear"], ["get", "mag"], 1, 5, 6, 50],
                ],
                // Color circle by earthquake magnitude
                "circle-color": [
                  "interpolate",
                  ["linear"],
                  ["get", "mag"],
                  1,
                  "rgba(33,102,172,0)",
                  2,
                  "rgb(103,169,207)",
                  3,
                  "rgb(209,229,240)",
                  4,
                  "rgb(253,219,199)",
                  5,
                  "rgb(239,138,98)",
                  6,
                  "rgb(178,24,43)",
                ],
                "circle-stroke-color": "white",
                "circle-stroke-width": 1,
                // Transition from heatmap to circle layer by zoom level
                "circle-opacity": [
                  "interpolate",
                  ["linear"],
                  ["zoom"],
                  7,
                  0,
                  8,
                  1,
                ],
              },
            },
            "waterway-label",
          );
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });

      GetAllMembers(idToken)
        .then((response) => {
          const totalMembers = response.length;
          let totalActiveMembers = 0;

          response.forEach((member) => {
            if (member.membershipStatus === "ACTIVE") {
              totalActiveMembers = totalActiveMembers + 1;
            }
          });

          setPercentageActive(
            Math.round((totalActiveMembers / totalMembers) * 100),
          );

          setMembersLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    });
  });

  return (
    <Container>
      <ContentWithPaddingXl>
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/profile">My Account</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink>Reports</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <SectionHeadingLeft>Key Performance Metrics</SectionHeadingLeft>
        <TileContainer>
          <Tile>
            <StatisticContainer>
              <Skeleton isLoaded={!loading}>
                <StatisticHeading>
                  {stats?.sumOfAdults ? stats.sumOfAdults : 0}
                </StatisticHeading>
              </Skeleton>
              <StatisticDescription>Adult Members</StatisticDescription>
            </StatisticContainer>
          </Tile>
          <Tile>
            <StatisticContainer>
              <Skeleton isLoaded={!loading}>
                <StatisticHeading>
                  {stats?.sumOfJuniors ? stats.sumOfJuniors : 0}
                </StatisticHeading>
              </Skeleton>
              <StatisticDescription>Junior Members</StatisticDescription>
            </StatisticContainer>
          </Tile>
          <Tile>
            <StatisticContainer>
              <Skeleton isLoaded={!membersLoading}>
                <StatisticHeading>{percentageActive}%</StatisticHeading>
              </Skeleton>
              <StatisticDescription>Active Members</StatisticDescription>
            </StatisticContainer>
          </Tile>
        </TileContainer>

        <ChackraTabs
          position="relative"
          variant="line"
          align="center"
          colorScheme="black"
          size="lg"
        >
          <TabList>
            <Tab>Georgraphical Distribution</Tab>
            <Tab>Gender Statistics</Tab>
            <Tab>Demographic Statistics</Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="red.500"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel>
              <div>
                <MapContainer ref={mapContainer} className="map-container" />
              </div>
            </TabPanel>
            <TabPanel>
              <SubHeadingHeadingLeft>
                Senior membership numbers by gender
              </SubHeadingHeadingLeft>
              <ResponsiveContainer width="100%" height={500}>
                <StackedAreaChart data={stats?.adultsByGender} />
              </ResponsiveContainer>

              <SubHeadingHeadingLeft>
                Junior membership numbers by gender
              </SubHeadingHeadingLeft>
              <ResponsiveContainer width="100%" height={500}>
                <StackedAreaChart data={stats?.juniorsByGender} />
              </ResponsiveContainer>
            </TabPanel>
            <TabPanel>
              <SubHeadingHeadingLeft>Membership by age</SubHeadingHeadingLeft>
              <ResponsiveContainer width="100%" height={500}>
                <StackedAgeAreaChart data={stats?.membersByAge} />
              </ResponsiveContainer>
            </TabPanel>
          </TabPanels>
        </ChackraTabs>
      </ContentWithPaddingXl>
    </Container>
  );
};
