import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

export const NavLinks = tw.div`tw-inline-block`;
export const NavLink = tw.a`
 tw-text-lg tw-my-2 lg:tw-text-sm lg:tw-mx-6 lg:tw-my-0
 tw-font-semibold tw-tracking-wide tw-transition tw-duration-300
 tw-pb-1 tw-border-b-2 tw-border-transparent hover:tw-border-primary-500 hocus:tw-text-primary-500
`;
export const PrimaryLink = tw(NavLink)`
  lg:tw-mx-0
  tw-px-8 tw-py-3 tw-rounded tw-bg-primary-500 tw-text-gray-100
  hocus:tw-bg-primary-700 hocus:tw-text-gray-200 focus:tw-shadow-outline
 tw-border-b-0
`;
