import { extendBaseTheme } from "@chakra-ui/react";
import { theme as chakraTheme } from "@chakra-ui/theme";

export const {
  Button,
  Tooltip,
  Tabs,
  Drawer,
  Input,
  Skeleton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Accordion,
  useToast,
  toast,
  Alert,
  Modal,
  PinInput,
  Form,
  Switch,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Box,
  Select,
  ButtonGroup,
  FormErrorMessage,
  FocusLock,
  FormControl,
  FormLabel,
  FormError,
  Text,
  IconButton,
  Stack,
  Checkbox,
  CheckboxGroup,
  FormHelperText,
  useDisclosure,
  Textarea,
} = chakraTheme.components;

export const customChakraTheme = extendBaseTheme({
  components: {
    Tooltip,
    Popover,
    CheckboxGroup,
    Text,
    Switch,
    Select,
    FormError,
    Form,
    FormErrorMessage,
    FormHelperText,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
    Box,
    ButtonGroup,
    FocusLock,
    FormControl,
    FormLabel,
    IconButton,
    Stack,
    useDisclosure,
    PinInput,
    Drawer,
    Button,
    Checkbox,
    Tabs,
    Input,
    Skeleton,
    PopoverContent,
    Accordion,
    useToast,
    toast,
    Alert,
    Modal,
    Textarea,
    PopoverTrigger,
  },
  colors: {
    red: {
      100: "#ff7373",
      200: "#ff5b5b",
      300: "#ff4447",
      400: "#ff2c2c",
      500: "#ff1515",
      600: "#e61313",
      700: "#cc1111",
      800: "#b30f0f",
      900: "#990d0d",
    },
  },
});
