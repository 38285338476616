import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import Header from "components/header/Header.js";
import { Description, Heading, HeroRow, NavLink } from "./NotFound.styles.js";

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Header />
      <Container tw="-tw-mx-8 -tw-mt-8 tw-pt-8 tw-px-8">
        <Content2Xl>
          <HeroRow>
            <Heading>This page doesn't seem to exist</Heading>
            <Description>
              Want to go back to the BAD TRI home page?{" "}
              <NavLink href={"./"}>click here</NavLink>.
            </Description>
          </HeroRow>
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};
