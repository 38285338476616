import React, { useCallback, useEffect, useState } from "react";
import tw from "twin.macro";
import { UpdateMemberDetails } from "api/secureApi.ts";
import { GetCookie } from "api/jwt.js";
import {
  Box,
  Button,
  ButtonGroup,
  FocusLock,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Select,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { AddressAutofill } from "@mapbox/search-js-react";

const dateDisplayOptions = {
  day: "numeric",
  month: "long",
  year: "numeric",
};

const Subheading = tw.div`tw-relative tw-text-lg lg:tw-text-xl tw-font-semibold tw-inline-block`;
const HR = tw.div`tw-h-5 tw-w-full tw-mix-blend-color-burn`;

const AddressBox = tw.div`tw-inline-block`;
const ColumnInformationContainer = tw.div`tw-relative md:tw-grid tw-w-full md:tw-grid-cols-2`;

const FieldHeading = tw.div`tw-font-semibold tw-text-sm`;
const FieldValue = tw.div`tw-text-xl tw-font-thin`;
const FieldContainer = tw.div`tw-relative tw-mt-3`;

export default ({
  memberDetails,
  setMemberDetails,
  isEditable,
  sectionTitle,
}) => {
  const [readyToUpdate, setReadyToUpdate] = useState(false);
  const toast = useToast();
  const [parentFamilyName, setParentFamilyName] = useState(
    memberDetails == null ? null : memberDetails.parentFamilyName,
  );
  const [parentPhoneNumber, setParentPhoneNumber] = useState(
    memberDetails == null ? null : memberDetails.parentPhoneNumber,
  );
  const [parentFirstName, setParentFirstName] = useState(
    memberDetails == null ? null : memberDetails.parentFirstName,
  );
  const [parentEmailAddress, setParentEmailAddress] = useState(
    memberDetails == null ? null : memberDetails.parentEmailAddress,
  );
  const [familyName, setFamilyName] = useState(
    memberDetails == null ? null : memberDetails.familyName,
  );
  const [phoneNumber, setPhoneNumber] = useState(
    memberDetails == null ? null : memberDetails.phoneNumber,
  );
  const [gender, setGender] = useState(
    memberDetails == null ? null : memberDetails.gender,
  );
  const [addressLine1, setAddressLine1] = useState(
    memberDetails == null ? null : memberDetails.addressLine1,
  );
  const [addressLine2, setAddressLine2] = useState(
    memberDetails == null ? null : memberDetails.addressLine2,
  );
  const [postCode, setPostCode] = useState(
    memberDetails == null ? null : memberDetails.postCode,
  );
  const [lat, setLat] = useState(
    memberDetails == null ? null : memberDetails.latitude,
  );
  const [long, setLong] = useState(
    memberDetails == null ? null : memberDetails.longitude,
  );

  const handleRetrieve = useCallback((res) => {
    document.getElementById("addressLine1").value =
      res.features[0].properties.address_line1;
    document.getElementById("postCode").value =
      res.features[0].properties.postcode;
    document.getElementById("lat").value =
      res.features[0].geometry.coordinates[1];
    document.getElementById("long").value =
      res.features[0].geometry.coordinates[0];

    document.getElementById("postCode").style.display = "block";
    document.getElementById("addressLine2").style.display = "block";
  }, []);

  useEffect(() => {
    if (readyToUpdate) {
      saveDetails();
    }
  }, [
    phoneNumber,
    familyName,
    addressLine1,
    addressLine2,
    postCode,
    readyToUpdate,
  ]);

  const saveDetails = function () {
    UpdateMemberDetails(GetCookie("idToken"), memberDetails.membershipNumber, {
      familyName: familyName,
      gender: gender,
      phoneNumber: phoneNumber,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      postCode: postCode,
      lat: lat + "",
      long: long + "",
      parentFirstName: parentFirstName,
      parentFamilyName: parentFamilyName,
      parentEmailAddress: parentEmailAddress,
      parentPhoneNumber: parentPhoneNumber,
    })
      .then((response) => {
        setMemberDetails(response);
        //Success - show toast
        toast({
          title: "Successfully updated 🏊🚴🏃",
          status: "success",
          isClosable: true,
        });
      })
      .catch((err) => {
        //Error - todo reset variables
        toast({
          title: "Something went wrong - refresh the page and try again!",
          status: "error",
          isClosable: true,
        });
      });
  };

  const TextInput = React.forwardRef((props, ref) => {
    return (
      <FormControl>
        <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
        <Input ref={ref} id={props.id} {...props} />
      </FormControl>
    );
  });

  const NumberInput = React.forwardRef((props, ref) => {
    return (
      <FormControl>
        <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
        <Input type="number" ref={ref} id={props.id} {...props} />
      </FormControl>
    );
  });

  const TextForm = ({ defaultValue, id, label, onSubmit, onCancel }) => {
    return (
      <Stack spacing={4}>
        <TextInput label={label} id={id} defaultValue={defaultValue} />
        <ButtonGroup display="flex" justifyContent="flex-end">
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={onSubmit}>
            Save
          </Button>
        </ButtonGroup>
      </Stack>
    );
  };

  const AddressSelector = ({ defaultValue, id, label, onSubmit, onCancel }) => {
    return (
      <Stack spacing={4}>
        <FormControl>
          <FormLabel>Address</FormLabel>

          <AddressAutofill
            options={{
              language: "en",
              country: "UK",
              streets: false,
            }}
            accessToken="pk.eyJ1IjoibWFya2Fsc3RvbiIsImEiOiJjangzYWt0ZHYwMTdzNDlrdDhtMHl0a3MyIn0.LWzTJORvF1PLlosFIAEyjQ"
            onRetrieve={handleRetrieve}
          >
            <Input
              tw="tw-mb-2"
              size="lg"
              id="addressLine1"
              placeholder="15 example road, BS1 7ER"
              name="address"
              autoComplete="address-line1"
              type="text"
            />
          </AddressAutofill>
          <Input
            tw="tw-mb-2"
            style={{ display: "none" }}
            size={"lg"}
            placeholder="Flat, building, floor etc."
            id="addressLine2"
            name="address"
            autoComplete="address-line2"
            type="text"
          />
          <Input
            style={{ display: "none" }}
            id="postCode"
            size={"lg"}
            contentEditable={"false"}
            name="postcode"
            placeholder="Postcode"
            type="text"
            autoComplete="postal-code"
            readOnly={true}
          />
          <Input
            style={{ display: "none" }}
            id="lat"
            size={"lg"}
            contentEditable={"false"}
            name="postcode"
            placeholder="Lat"
            autoComplete="postal-code"
            readOnly={true}
          />
          <Input
            style={{ display: "none" }}
            id="long"
            size={"lg"}
            contentEditable={"false"}
            name="postcode"
            placeholder="Long"
            type="text"
            autoComplete="postal-code"
            readOnly={true}
          />
        </FormControl>
      </Stack>
    );
  };

  const NumberForm = ({ defaultValue, id, label, onSubmit, onCancel }) => {
    return (
      <Stack spacing={4}>
        <NumberInput label={label} id={id} defaultValue={defaultValue} />
        <ButtonGroup display="flex" justifyContent="flex-end">
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={onSubmit}>
            Save
          </Button>
        </ButtonGroup>
      </Stack>
    );
  };

  const FamilyName = () => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const firstFieldRef = React.useRef(null);

    return (
      <>
        <Box fontSize="xl" fontWeight="thin" display="inline-block" mr={3}>
          {familyName === undefined ? " - " : familyName}
        </Box>
        <Popover
          isOpen={isOpen}
          initialFocusRef={firstFieldRef}
          onOpen={onOpen}
          onClose={onClose}
          closeOnBlur={false}
        >
          {isEditable && (
            <PopoverTrigger>
              <IconButton size="xs" tw="tw-bg-gray-400" icon={<EditIcon />} />
            </PopoverTrigger>
          )}
          <PopoverContent p={5}>
            <FocusLock returnFocus persistentFocus={false}>
              <PopoverArrow />
              <PopoverCloseButton />
              <TextForm
                defaultValue={memberDetails.familyName}
                id="familyName"
                label="Family Name"
                onSubmit={() => {
                  setReadyToUpdate(true);
                  setFamilyName(document.getElementById("familyName").value);
                }}
                onCancel={onClose}
              />
            </FocusLock>
          </PopoverContent>
        </Popover>
      </>
    );
  };

  const ParentFamilyName = () => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const firstFieldRef = React.useRef(null);

    return (
      <>
        <Box fontSize="xl" fontWeight="thin" display="inline-block" mr={3}>
          {parentFamilyName === undefined ? " - " : parentFamilyName}
        </Box>
        <Popover
          isOpen={isOpen}
          initialFocusRef={firstFieldRef}
          onOpen={onOpen}
          onClose={onClose}
          closeOnBlur={false}
        >
          {isEditable && (
            <PopoverTrigger>
              <IconButton size="xs" tw="tw-bg-gray-400" icon={<EditIcon />} />
            </PopoverTrigger>
          )}
          <PopoverContent p={5}>
            <FocusLock returnFocus persistentFocus={false}>
              <PopoverArrow />
              <PopoverCloseButton />
              <TextForm
                defaultValue={memberDetails.parentFamilyName}
                id="parentFamilyName"
                label="Parent / Guardian Family Name"
                onSubmit={() => {
                  setReadyToUpdate(true);
                  setParentFamilyName(
                    document.getElementById("parentFamilyName").value,
                  );
                }}
                onCancel={onClose}
              />
            </FocusLock>
          </PopoverContent>
        </Popover>
      </>
    );
  };

  const ParentEmailAddress = () => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const firstFieldRef = React.useRef(null);

    return (
      <>
        <Box fontSize="xl" fontWeight="thin" display="inline-block" mr={3}>
          {parentEmailAddress === undefined ? " - " : parentEmailAddress}
        </Box>
        <Popover
          isOpen={isOpen}
          initialFocusRef={firstFieldRef}
          onOpen={onOpen}
          onClose={onClose}
          closeOnBlur={false}
        >
          {isEditable && (
            <PopoverTrigger>
              <IconButton size="xs" tw="tw-bg-gray-400" icon={<EditIcon />} />
            </PopoverTrigger>
          )}
          <PopoverContent p={5}>
            <FocusLock returnFocus persistentFocus={false}>
              <PopoverArrow />
              <PopoverCloseButton />
              <TextForm
                defaultValue={memberDetails.parentEmailAddress}
                id="parentEmail"
                label="Parent / Guardian Email Address"
                onSubmit={() => {
                  setReadyToUpdate(true);
                  setParentEmailAddress(
                    document.getElementById("parentEmail").value,
                  );
                }}
                onCancel={onClose}
              />
            </FocusLock>
          </PopoverContent>
        </Popover>
      </>
    );
  };

  const ParentFirtstName = () => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const firstFieldRef = React.useRef(null);

    return (
      <>
        <Box fontSize="xl" fontWeight="thin" display="inline-block" mr={3}>
          {parentFirstName === undefined ? " - " : parentFirstName}
        </Box>
        <Popover
          isOpen={isOpen}
          initialFocusRef={firstFieldRef}
          onOpen={onOpen}
          onClose={onClose}
          closeOnBlur={false}
        >
          {isEditable && (
            <PopoverTrigger>
              <IconButton size="xs" tw="tw-bg-gray-400" icon={<EditIcon />} />
            </PopoverTrigger>
          )}
          <PopoverContent p={5}>
            <FocusLock returnFocus persistentFocus={false}>
              <PopoverArrow />
              <PopoverCloseButton />
              <TextForm
                defaultValue={memberDetails.parentFirstName}
                id="parentFirstName"
                label="Parent / Guardian First Name"
                onSubmit={() => {
                  setReadyToUpdate(true);
                  setParentFirstName(
                    document.getElementById("parentFirstName").value,
                  );
                }}
                onCancel={onClose}
              />
            </FocusLock>
          </PopoverContent>
        </Popover>
      </>
    );
  };

  const ParentPhoneNumber = () => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const firstFieldRef = React.useRef(null);

    return (
      <>
        <Box fontSize="xl" fontWeight="thin" display="inline-block" mr={3}>
          {parentPhoneNumber === undefined ? " - " : parentPhoneNumber}
        </Box>
        <Popover
          isOpen={isOpen}
          initialFocusRef={firstFieldRef}
          onOpen={onOpen}
          onClose={onClose}
          closeOnBlur={false}
        >
          {isEditable && (
            <PopoverTrigger>
              <IconButton size="xs" tw="tw-bg-gray-400" icon={<EditIcon />} />
            </PopoverTrigger>
          )}
          <PopoverContent p={5}>
            <FocusLock returnFocus persistentFocus={false}>
              <PopoverArrow />
              <PopoverCloseButton />
              <TextForm
                defaultValue={memberDetails.parentPhoneNumber}
                id="parentPhoneNumber"
                label="Parent / Guardian Phone Number"
                onSubmit={() => {
                  setReadyToUpdate(true);
                  setParentPhoneNumber(
                    document.getElementById("parentPhoneNumber").value,
                  );
                }}
                onCancel={onClose}
              />
            </FocusLock>
          </PopoverContent>
        </Popover>
      </>
    );
  };

  const PhoneNumber = () => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const firstFieldRef = React.useRef(null);

    return (
      <>
        <Box fontSize="xl" fontWeight="thin" display="inline-block" mr={3}>
          {phoneNumber === undefined ? " - " : phoneNumber}
        </Box>
        <Popover
          isOpen={isOpen}
          initialFocusRef={firstFieldRef}
          onOpen={onOpen}
          onClose={onClose}
          closeOnBlur={false}
        >
          {isEditable && (
            <PopoverTrigger>
              <IconButton size="xs" tw="tw-bg-gray-400" icon={<EditIcon />} />
            </PopoverTrigger>
          )}
          <PopoverContent p={5}>
            <FocusLock returnFocus persistentFocus={false}>
              <PopoverArrow />
              <PopoverCloseButton />
              <NumberForm
                defaultValue={memberDetails.phoneNumber}
                id="phoneNumber"
                label="Phone Number"
                onSubmit={() => {
                  setReadyToUpdate(true);
                  setPhoneNumber(document.getElementById("phoneNumber").value);
                }}
                onCancel={onClose}
              />
            </FocusLock>
          </PopoverContent>
        </Popover>
      </>
    );
  };

  const SelectInput = React.forwardRef((props, ref) => {
    return (
      <FormControl>
        <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
        <Select ref={ref} id={props.id} {...props}>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Other">Other</option>
        </Select>
      </FormControl>
    );
  });

  const GenderForm = ({ firstFieldRef, onCancel }) => {
    return (
      <Stack spacing={4}>
        <SelectInput id="gender" label="gender" placeholder="Select option">
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Other">Other</option>
        </SelectInput>
        <ButtonGroup display="flex" justifyContent="flex-end">
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            onClick={() => {
              setReadyToUpdate(true);
              setGender(document.getElementById("gender").value);
            }}
          >
            Save
          </Button>
        </ButtonGroup>
      </Stack>
    );
  };

  const Gender = () => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const firstFieldRef = React.useRef(null);

    return (
      <>
        <Box fontSize="xl" fontWeight="thin" display="inline-block" mr={3}>
          {gender === undefined ? " - " : gender}
        </Box>
        <Popover
          isOpen={isOpen}
          initialFocusRef={firstFieldRef}
          onOpen={onOpen}
          onClose={onClose}
          closeOnBlur={false}
        >
          {isEditable && (
            <PopoverTrigger>
              <IconButton size="xs" tw="tw-bg-gray-400" icon={<EditIcon />} />
            </PopoverTrigger>
          )}
          <PopoverContent p={5}>
            <FocusLock returnFocus persistentFocus={false}>
              <PopoverArrow />
              <PopoverCloseButton />
              <GenderForm onCancel={onClose} />
            </FocusLock>
          </PopoverContent>
        </Popover>
      </>
    );
  };

  const AddressForm = ({ firstFieldRef, onCancel }) => {
    return (
      <Stack spacing={4}>
        <AddressSelector />
        <ButtonGroup display="flex" justifyContent="flex-end">
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            onClick={() => {
              setAddressLine1(document.getElementById("addressLine1").value);
              setAddressLine2(document.getElementById("addressLine2").value);
              setPostCode(document.getElementById("postCode").value);
              setLat(document.getElementById("lat").value);
              setLong(document.getElementById("long").value);
              setReadyToUpdate(true);
            }}
          >
            Save
          </Button>
        </ButtonGroup>
      </Stack>
    );
  };

  const Address = () => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const firstFieldRef = React.useRef(null);

    return (
      <>
        <AddressBox>
          <Box fontSize="xl" fontWeight="thin" mr={3}>
            {addressLine1 === undefined ? " - " : addressLine1}
          </Box>
          <Box fontSize="xl" fontWeight="thin" mr={3}>
            {addressLine2 === undefined ? "" : addressLine2}
          </Box>
          <Box fontSize="xl" fontWeight="thin" mr={3}>
            {postCode === undefined ? " - " : postCode}
          </Box>
        </AddressBox>
        <Popover
          isOpen={isOpen}
          initialFocusRef={firstFieldRef}
          onOpen={onOpen}
          onClose={onClose}
          closeOnBlur={false}
        >
          {isEditable && (
            <PopoverTrigger>
              <IconButton
                size="xs"
                tw="tw-bg-gray-400 tw-absolute"
                icon={<EditIcon />}
              />
            </PopoverTrigger>
          )}
          <PopoverContent p={5}>
            <FocusLock returnFocus persistentFocus={false}>
              <PopoverArrow />
              <PopoverCloseButton />
              <AddressForm onCancel={onClose} />
            </FocusLock>
          </PopoverContent>
        </Popover>
      </>
    );
  };

  return (
    <>
      <Subheading>{sectionTitle}</Subheading>
      <ColumnInformationContainer>
        <FieldContainer>
          <FieldHeading>First Name</FieldHeading>
          <FieldValue>{memberDetails.firstName}</FieldValue>
        </FieldContainer>
        <FieldContainer>
          <FieldHeading>Family Name</FieldHeading>
          <FamilyName />
        </FieldContainer>

        <FieldContainer>
          <FieldHeading>Date of Birth</FieldHeading>
          <FieldValue>
            {new Date(memberDetails.dateOfBirth).toLocaleDateString(
              "en-GB",
              dateDisplayOptions,
            )}
          </FieldValue>
        </FieldContainer>
        <FieldContainer>
          <FieldHeading>Gender</FieldHeading>
          <Gender />
        </FieldContainer>
        <FieldContainer>
          <FieldHeading>Email Address</FieldHeading>
          <FieldValue>{memberDetails.emailAddress}</FieldValue>
        </FieldContainer>

        <FieldContainer>
          <FieldHeading>Phone Number</FieldHeading>
          <PhoneNumber />
        </FieldContainer>
        <FieldContainer>
          <FieldHeading>Address</FieldHeading>
          <Address />
        </FieldContainer>
        <FieldContainer>
          <FieldHeading>Membership Expiry</FieldHeading>
          <FieldValue>
            {new Date(memberDetails.membershipExpiry).toLocaleDateString(
              "en-GB",
              dateDisplayOptions,
            )}
          </FieldValue>
        </FieldContainer>
      </ColumnInformationContainer>
      {memberDetails.joinedAsJunior && (
        <>
          <HR></HR>
          <Subheading>Parent / Guardian Details</Subheading>
          <ColumnInformationContainer>
            <FieldContainer>
              <FieldHeading>Parent / Guardian First Name</FieldHeading>
              <ParentFirtstName />
            </FieldContainer>
            <FieldContainer>
              <FieldHeading>Parent / Guardian Family Name</FieldHeading>
              <ParentFamilyName />
            </FieldContainer>
            <FieldContainer>
              <FieldHeading>Parent / Guardian Email Address</FieldHeading>
              <ParentEmailAddress />
            </FieldContainer>
            <FieldContainer>
              <FieldHeading>Parent / Guardian Mobile Number</FieldHeading>
              <ParentPhoneNumber />
            </FieldContainer>
          </ColumnInformationContainer>
        </>
      )}
    </>
  );
};
