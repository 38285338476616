import React from "react";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings";
import CountUp from "react-countup";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-8.svg";

const ContentWithPadding = tw(ContentWithPaddingXl)`tw-pb-0`;
const HeadingContainer = tw.div`tw-text-center`;
const Subheading = tw(SubheadingBase)`tw-mb-4`;
const Heading = tw(SectionHeading)``;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`tw-pointer-events-none -tw-z-20 tw-absolute tw-left-0 tw-bottom-0 tw-h-64 tw-w-64 tw-opacity-15 tw-transform -tw-translate-x-2/3 tw-text-primary-500`}
`;
const TwoColumn = tw.div`tw-mb-10 md:tw-ml-10 md:tw-mr-10`;
const Description = tw.blockquote`tw-text-left tw-text-sm sm:tw-text-lg lg:tw-text-lg xl:tw-text-2xl tw-mt-5`;

export default ({ subheading = "", heading = "Scoring", description = "" }) => {
  return (
    <Container tw="tw-mb-32">
      <ContentWithPadding>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <TwoColumn>
          <Description>
            To score any points you must enter under the club name as 'Badtri'.
            The scoring for this year’s championships has been changed. It's
            still 'age-weighted', so using the same kind of philosophy as Park
            Run.
            <br />
            <br />
            Up to 100 points are on offer for each race result. The number of
            points you get for each race is specific to your own age group.
            You'll get a percentage score against the winner of your age group.
            If you come 1st, you get 100 points, otherwise the number of points
            you are awarded is based on your percentage placing in your age
            group.
            <br />
            <br />
            For example, if there are 20 people in your age category and you
            come 5th, your result is 75% of the best age group result and you'll
            be awarded 75 points.
            <br />
            <br />
            Marshalling is awarded at 100 points per race. That includes every
            race, and yes, if you only marshal races for the whole season you
            could win the championships based on marshaling alone. The idea of
            this is to support the races that support us, still encourage Badtri
            member participation and reward those highly, who give up their time
            to help others to race.
          </Description>
        </TwoColumn>
      </ContentWithPadding>
      <DecoratorBlob2 />
    </Container>
  );
};
