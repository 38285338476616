import { GetCookie } from "api/jwt";
import { GetSessions } from "api/openApi.ts";
import { DeleteSession, UpsertSession } from "api/secureApi.ts";

const { useState } = require("react");

const useGetSessions = () => {
  const [sessions, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const upsertSessions = async (session) => {
    const idToken = GetCookie("idToken");
    await UpsertSession(idToken, session);
    const sessions = await GetSessions();
    setSessions(sessions.sessions);
  };

  const deleteSessions = async (sessionID) => {
    const idToken = GetCookie("idToken");
    await DeleteSession(idToken, sessionID);
    const sessions = await GetSessions();
    setSessions(sessions.sessions);
  };

  const getSessions = async () => {
    setIsLoading(true);
    const sessions = await GetSessions();
    setSessions(sessions.sessions);
    setIsLoading(false);
  };

  return { getSessions, sessions, isLoading, upsertSessions, deleteSessions };
};

export default useGetSessions;
