import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Container = tw.div`tw-relative`;
const HighlightedText = tw.span`tw-bg-primary-500 tw-text-gray-100 tw-px-4 tw-transform -tw-skew-x-12 tw-inline-block`;
const TwoColumn = tw.div`tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-max-w-screen-xl tw-mx-auto tw-py-20 md:tw-py-24 tw-items-center`;
const Column = tw.div`tw-w-full tw-max-w-md tw-mx-auto md:tw-max-w-none md:tw-mx-0`;
const TextColumn = styled(Column)((props) => [
  tw`md:tw-w-6/12 tw-mt-16 md:tw-mt-0`,
  props.textOnLeft
    ? tw`md:tw-mr-12 lg:tw-mr-16 md:tw-order-first`
    : tw`md:tw-ml-12 lg:tw-ml-16 md:tw-order-last`,
]);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`tw-pointer-events-none -tw-z-20 tw-absolute tw-right-0 tw-top-0 tw-h-56 tw-w-56 tw-opacity-15 tw-transform tw-translate-x-2/3 -tw-translate-y-12 tw-text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`tw-pointer-events-none -tw-z-20 tw-absolute tw-left-0 tw-bottom-0 tw-h-64 tw-w-64 tw-opacity-15 tw-transform -tw-translate-x-2/3 tw-text-primary-500`}
`;

const TextContent = tw.div`lg:tw-py-8 tw-text-center md:tw-text-left`;

const Subheading = tw(SubheadingBase)`tw-text-center md:tw-text-left`;
const Heading = tw(
  SectionHeading,
)`tw-mt-4 tw-font-black tw-text-left tw-text-3xl sm:tw-text-4xl lg:tw-text-5xl tw-text-center md:tw-text-left tw-leading-tight`;

const Steps = tw.ul`tw-mt-12`;
const Step = tw.li`tw-mt-8 tw-flex tw-flex-col md:tw-flex-row tw-items-center md:tw-items-start`;
const StepNumber = tw.div`tw-font-semibold tw-text-4xl tw-leading-none tw-text-gray-400`;
const StepText = tw.div`tw-mt-3 md:tw-mt-0 md:tw-ml-6`;
const StepHeading = tw.h6`tw-leading-none tw-text-xl tw-font-semibold`;
const StepDescription = tw.p`tw-mt-3 tw-max-w-xs tw-leading-loose tw-text-sm tw-text-gray-600 tw-font-medium`;

export default ({
  subheading = "Joining instructions",
  heading = (
    <>
      How to join <br></br> <HighlightedText>BAD TRI</HighlightedText>
    </>
  ),
  steps = null,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const defaultSteps = [
    {
      heading: "Create an account",
      description: "Fill out the signup form below to create an account.",
    },
    {
      heading: "Pay membership",
      description:
        "Once logged in you'll be promted to pay an annual membership fee. This is currently £36 for seniors and £21 for juniors. Special discounts are available for students, and partner clubs - contact us for more information.",
    },
    {
      heading: "Request to join Spond",
      description:
        "We use a third party booking app to manage session bookings. Once you've paid membership you'll be given a joining link. Once you've requested to join the Spond group using the link we provide you, an admin will admit you to the group. You can then book sessions, chat with club members, and have some fun! If this doesn't happen within 24 hours contact us.",
    },
  ];

  if (!steps) steps = defaultSteps;

  return (
    <Container>
      <TwoColumn>
        <TextColumn>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Steps>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepNumber>
                    {(index + 1).toString().padStart(2, "0")}
                  </StepNumber>
                  <StepText>
                    <StepHeading>{step.heading}</StepHeading>
                    <StepDescription>{step.description}</StepDescription>
                  </StepText>
                </Step>
              ))}
            </Steps>
          </TextContent>
        </TextColumn>
      </TwoColumn>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
