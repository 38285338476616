import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  ResponsiveContainer,
} from "recharts";

export default function StackedAgeAreaChart(props) {
  let displayData = [];

  return (
    <ResponsiveContainer>
      <AreaChart
        margin={{ top: 50, right: 0, left: 0, bottom: 50 }}
        data={props.data == undefined ? displayData : props.data}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="sum_0_17"
          connectNulls
          stackId="2"
          stroke="#333333"
          fill="#F77F7F"
        />
        <Area
          type="monotone"
          connectNulls
          dataKey="sum_18_24"
          stackId="2"
          stroke="#333333"
          fill="#F7C27F"
        />
        <Area
          type="monotone"
          connectNulls
          dataKey="sum_25_34"
          stackId="2"
          stroke="#333333"
          fill="#F7F77F"
        />
        <Area
          type="monotone"
          connectNulls
          dataKey="sum_35_44"
          stackId="2"
          stroke="#333333"
          fill="#7FF7F4"
        />
        <Area
          type="monotone"
          connectNulls
          dataKey="sum_45_54"
          stackId="2"
          stroke="#333333"
          fill="#7F9CF7"
        />
        <Area
          type="monotone"
          connectNulls
          dataKey="sum_55_64"
          stackId="2"
          stroke="#333333"
          fill="#B47FF7"
        />
        <Area
          type="monotone"
          connectNulls
          dataKey="sum_65_plus"
          stackId="2"
          stroke="#333333"
          fill="#F27FF7"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
