import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Link as ChakraLink } from "@chakra-ui/react";
import LogoImage from "images/BADtri_logo_black.svg";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "images/instagram-icon.svg";
import { ReactComponent as StravaIcon } from "images/strava.svg";

const Container = tw.div`tw-relative tw-bg-gray-200 tw-text-gray-700 -tw-mb-8 -tw-mx-8 tw-px-8 tw-py-20 lg:tw-py-24`;
const Content = tw.div`tw-max-w-screen-xl tw-mx-auto tw-relative tw-z-10`;
const SixColumns = tw.div`tw-flex tw-flex-wrap tw-text-center sm:tw-text-left tw-justify-center sm:tw-justify-start md:tw-justify-evenly -tw-mt-12`;

const Column = tw.div` tw-px-4 sm:tw-px-0 sm:tw-w-1/4 md:tw-w-auto tw-mt-12`;

const ColumnHeading = tw.h5`tw-uppercase tw-font-bold`;

const LinkList = tw.ul`tw-mt-6 tw-text-sm tw-font-medium`;
const LinkListItem = tw.li`tw-mt-3`;
const Link = tw.a`tw-border-b-2 tw-border-transparent hocus:tw-border-gray-700 tw-pb-1 tw-transition tw-duration-300`;

const SubscribeNewsletterColumn = tw(
  Column,
)`tw-text-center lg:tw-text-left tw-w-full! lg:tw-w-auto! tw-mt-20 lg:tw-mt-12`;
const SubscribeNewsletterContainer = tw.div`tw-max-w-sm tw-mx-auto lg:tw-mx-0 `;
const SubscribeText = tw.p`tw-mt-2 lg:tw-mt-6 tw-text-sm tw-font-medium tw-text-gray-600`;
const Divider = tw.div`tw-my-16 tw-border-b-2 tw-border-gray-300 tw-w-full`;
const ThreeColRow = tw.div`tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-between`;
const LogoContainer = tw.div`tw-flex tw-items-center tw-justify-center md:tw-justify-start`;
const LogoImg = tw.img`tw-w-24`;
const SocialLinksContainer = tw.div`tw-mt-8 md:tw-mt-0 tw-flex`;
const SocialLink = styled.a`
  ${tw` tw-cursor-pointer tw-p-2 tw-rounded-full tw-bg-gray-900 tw-text-gray-100 hover:tw-bg-gray-700 tw-transition tw-duration-300 tw-mr-4 last:tw-mr-0`}
  svg {
    ${tw`tw-w-4 tw-h-4`}
  }
`;

export default () => {
  return (
    <Container>
      <Content>
        <SixColumns>
          <Column>
            <ColumnHeading>Main</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/sessions">Sessions</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/juniors">Junior Section</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/contact-us">Contact Us</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/championships">Club Chamionships</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/login">Sign In</Link>
              </LinkListItem>
              {/* <LinkListItem>
                <Link href="/shop">Club Kit</Link>
              </LinkListItem> */}
              <LinkListItem>
                <Link href="/join">Join Us</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Useful Information</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/documents">Documents</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/day-membership">Day Membership Form</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/coaches">Club Volunteers</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <SubscribeNewsletterColumn>
            <SubscribeNewsletterContainer>
              <ColumnHeading>Website Information</ColumnHeading>
              <SubscribeText>
                If you have any issues with the website, contact us and quote
                the current version: {process.env.REACT_APP_VERSION}. Website
                developed by{" "}
                <ChakraLink
                  textDecoration={"underline"}
                  href="https://alston-applications.co.uk"
                >
                  Alston Applications
                </ChakraLink>
                .
              </SubscribeText>
            </SubscribeNewsletterContainer>
          </SubscribeNewsletterColumn>
        </SixColumns>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            <LogoImg alt="logo" src={LogoImage} />
          </LogoContainer>
          <SocialLinksContainer>
            <SocialLink
              href="https://www.facebook.com/groups/badtri"
              SameSite="Strict"
            >
              <FacebookIcon />
            </SocialLink>
            <SocialLink
              href="https://www.instagram.com/bad.tri/"
              SameSite="Strict"
            >
              <InstagramIcon />
            </SocialLink>
            <SocialLink
              href="https://www.strava.com/clubs/badtri"
              SameSite="Strict"
            >
              <StravaIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
    </Container>
  );
};
