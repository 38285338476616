import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { Subheading } from "components/misc/Headings";
import { Button, Input, HStack } from "@chakra-ui/react";

export const Container = tw(
  ContainerBase,
)`tw-min-h-screen tw-bg-primary-900 tw-text-white tw-font-medium tw-flex tw-justify-center -tw-m-8`;
export const Content = tw.div`tw-max-w-screen-xl tw-m-0 sm:tw-mx-20 sm:tw-my-16 tw-bg-white tw-text-gray-900 tw-shadow sm:tw-rounded-lg tw-flex tw-justify-center tw-flex-1`;
export const MainContainer = tw.div`lg:tw-w-1/2 xl:tw-w-5/12 tw-p-6 sm:tw-p-12`;
export const LogoLink = tw.a``;
export const LogoImage = tw.img`tw-h-12 tw-mx-auto`;
export const MainContent = tw.div`tw-mt-12 tw-flex tw-flex-col tw-items-center`;
export const Heading = tw.h1`tw-text-2xl xl:tw-text-3xl tw-font-extrabold`;
export const FormContainer = tw.div`tw-w-full tw-flex-1 tw-mt-16`;
export const SubHeading = tw(
  Subheading,
)`tw-text-gray-600 tw-mt-5 tw-text-lg tw-mx-8`;
export const ChakraButton = tw(Button)`tw-mt-8 tw-w-full`;

export const Label = tw.label`tw-text-white`;
export const Form = tw.form`tw-mx-auto tw-max-w-xs`;
export const ChakraInput = tw(Input)`tw-mb-3`;
export const IllustrationContainer = tw.div`sm:tw-rounded-r-lg tw-flex-1 tw-bg-purple-100 tw-text-center tw-overflow-hidden tw-hidden lg:tw-flex tw-justify-center`;
export const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`tw-w-full tw-bg-cover tw-bg-center tw-bg-no-repeat`}
`;
export const Error = tw.div`tw-border-4 tw-border-solid tw-mt-5 tw-p-5 tw-border-primary-500`;
export const ErrorTitle = tw.div`tw-font-bold tw-mb-2`;
export const ChakraHStack = tw(HStack)`tw-ml-auto tw-mr-auto tw-justify-center	`;
