import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "components/header/Header.js";
import { GetCookie, IsAdult } from "api/jwt.js";
import { GetMember } from "api/secureApi.ts";
import Details from "components/account/privilegedViews/Details.js";

export default () => {
  const [memberDetails, setMemberDetails] = useState(null);
  const [adult, setIsAdult] = useState(true);
  const [loading, setIsLoading] = useState(true);

  const idToken = GetCookie("idToken");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const membershipNumber = urlParams.get("id");

  const refreshMemberDetails = function () {
    GetMember(idToken, membershipNumber).then((response) => {
      setMemberDetails(response);
    });
  };

  try {
    if (memberDetails == null) {
      GetMember(idToken, membershipNumber).then((response) => {
        setMemberDetails(response);
        setIsAdult(IsAdult(response.dateOfBirth));
        setIsLoading(false);
      });
    }
  } catch (e) {}

  return (
    <AnimationRevealPage>
      <Header />
      <Details
        isAdult={adult}
        isLoading={loading}
        memberDetails={memberDetails}
        refreshMemberDetails={refreshMemberDetails}
      ></Details>
      <Footer />
    </AnimationRevealPage>
  );
};
