import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";

const Card = tw.div`tw-mt-12 tw-w-full sm:tw-w-1/2 lg:tw-w-1/3 tw-flex tw-flex-col tw-items-center`;
const Cards = tw.div`tw-flex tw-flex-wrap tw-flex-row tw-justify-center sm:tw-max-w-2xl lg:tw-max-w-5xl tw-mx-auto`;
const CardImage = styled.div`
  ${(props) => css`
    background-image: url("${props.imageSrc}");
  `}
  ${tw`tw-w-64 tw-h-64 tw-bg-cover tw-bg-center tw-rounded`}
`;
const CardContent = styled.div`
  ${tw`tw-flex tw-flex-col tw-items-center tw-mt-6`}
  .position {
    ${tw`tw-uppercase tw-font-bold tw-tracking-widest tw-text-xs tw-text-primary-500`}
  }
  .name {
    ${tw`tw-mt-1 tw-text-xl tw-font-medium tw-text-gray-900`}
  }
`;

export default ({
  committee = [
    {
      imageSrc: "/HansPeterKiekuth.jpeg",
      position: "Chairman",
      name: "Hans-Peter Kiekuth",
    },
    {
      imageSrc: "/JennyCampbellRitchie.jpg",
      position: "Kit Secretary",
      name: "Jenny Campbell-Ritchie",
    },
    {
      imageSrc: "/PatrickNewman.jpeg",
      position: "Welfare Officer",
      name: "Patrick Newman",
    },
    {
      imageSrc: "/MalcolmCampbellRitchie.jpg",
      position: "Membership Secretary",
      name: "Malcolm Campbell-Ritchie",
    },
    {
      imageSrc: "/JamesFox.jpg",
      position: "Treasurer",
      name: "James Fox",
    },
    {
      imageSrc: "/MarkAlston.jpeg",
      position: "Webmaster",
      name: "Mark Alston",
    },
  ],
}) => {
  return (
    <>
      {committee.map((card, index) => (
        <Card key={index}>
          <CardImage imageSrc={card.imageSrc} />
          <CardContent>
            <span className="position">{card.position}</span>
            <span className="name">{card.name}</span>
          </CardContent>
        </Card>
      ))}
    </>
  );
};
