import React, { useEffect, useState } from "react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import useGetAddresses from "hooks/useGetAddresses";
import { Spinner } from "@chakra-ui/react";

export default ({
  setAddressLine1,
  setAddressLine2,
  setPostCode,
  setLat,
  setLong,
  setAddressInvalid,
  setFullAddress,
  value,
}) => {
  const { addresses, isLoading, setAddressQuery } = useGetAddresses();
  const [query, setQuery] = useState("");
  const [debouceWaiting, setDebounceIsWaiting] = useState(false);

  useEffect(() => {
    setDebounceIsWaiting(true);
    const timer = setTimeout(() => {
      setAddressQuery(query);
      setDebounceIsWaiting(false);
    }, 400);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <AutoComplete
      openOnFocus={false}
      isLoading={isLoading || debouceWaiting}
      disableFilter={true}
      value={value}
      onSelectOption={(e) => {
        const address = e.item.originalValue;
        if (setAddressLine1) {
          setAddressLine1(address.ADDRESS.split(",")[0].trim());
        }
        if (setAddressLine2) {
          setAddressLine2(address.ADDRESS.split(",")[1].trim());
        }
        if (setPostCode) {
          setPostCode(address.POSTCODE);
        }
        if (setLat) {
          setLat(address.LAT);
        }
        if (setLong) {
          setLong(address.LNG);
        }
        if (setAddressInvalid) {
          setAddressInvalid(false);
        }
        if (setFullAddress) {
          setFullAddress(address.ADDRESS);
        }
      }}
    >
      <AutoCompleteInput
        onChange={(e) => {
          if (e.target.value.length > 4) {
            setQuery(e.target.value);
          }
        }}
        loadingIcon={<Spinner />}
        placeholder="15 Example road, BS1 2QS"
      />
      <AutoCompleteList>
        {addresses.map((address) => (
          <AutoCompleteItem
            key={`option-${address.ADDRESS}`}
            value={address}
            textTransform="capitalize"
          >
            {address.ADDRESS}
          </AutoCompleteItem>
        ))}
      </AutoCompleteList>
    </AutoComplete>
  );
};
