import React from "react";
import GlobalStyles from "styles/GlobalStyles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ChakraBaseProvider } from "@chakra-ui/react";
import DayMembershipForm from "pages/dayMembershipFormPage/DayMembershipForm";
import LandingPage from "pages/landingPage/LandingPage";
import ClubChampionships from "pages/clubChampionshipsPage/ClubChampionships";
import Documents from "pages/documentsPage/Documents";
import ClubVolunteers from "pages/clubVolunteersPage/ClubVolunteers";
import JoinUs from "pages/joinUsPage/JoinUs";
import JuniorSection from "pages/juniorSectionPage/JuniorSection";
import ContactUs from "pages/contactUsPage/ContactUs";
import Login from "pages/loginPage/Login";
import Sessions from "pages/sessionsPage/Sessions";
import PasswordReset from "pages/passwordResetPage/PasswordReset";
import Profile from "pages/ProfilePage/Profile";
import MembershipDatabase from "pages/membershipDatabasePage/membershipDatabase";
import MemberDetails from "pages/memberDetailsPage/MemberDetails";
import NotFound from "pages/notFoundPage/NotFound";
import Reports from "pages/reportsPage/Reports";
import { customChakraTheme } from "chakra.config";
import { Notice } from "styles/commonComponentStyles";
import Verify from "pages/verifyPage/Verify";
import EditSessions from "pages/editSessionsPage/EditSessions";

export default function App() {
  return (
    <>
      <ChakraBaseProvider theme={customChakraTheme}>
        <GlobalStyles />
        <Router>
          <Routes>
            <Route path="/sessions" element={<Sessions />} />
            <Route path="/coaches" element={<ClubVolunteers />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/join" element={<JoinUs />} />
            <Route path="/documents" element={<Documents />} />
            <Route path="/login" element={<Login />} />
            <Route path="/password-reset" element={<PasswordReset />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/edit-sessions" element={<EditSessions />} />
            <Route path="/day-membership" element={<DayMembershipForm />} />
            <Route path="/juniors" element={<JuniorSection />} />
            <Route path="/championships" element={<ClubChampionships />} />
            <Route
              path="/membership-database"
              element={<MembershipDatabase />}
            />
            <Route path="/member/*" element={<MemberDetails />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </ChakraBaseProvider>
      {process.env.REACT_APP_ENVIRONMENT === "staging" && (
        <Notice>STAGING ENVIRONMENT</Notice>
      )}
    </>
  );
}
