import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import {
  Container as ContainerBase,
  ContentWithPaddingXl,
} from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";

const Container = tw(
  ContainerBase,
)`tw-my-8 lg:tw-my-10 tw-bg-primary-900 tw-text-gray-100 -tw-mx-8 tw-px-8`;
const HeadingContainer = tw.div``;
const Heading = tw(
  SectionHeading,
)`sm:tw-text-3xl md:tw-text-4xl lg:tw-text-5xl`;
const Subheading = tw(SubheadingBase)`tw-text-gray-100 tw-text-center`;
const Description = tw(
  SectionDescription,
)`tw-text-gray-400 tw-text-center tw-mx-auto tw-max-w-screen-md`;

const RacesContainer = tw.div`tw-mt-8 tw-flex tw-flex-col sm:tw-flex-row tw-items-center tw-justify-center tw-flex-wrap tw-max-w-screen-md tw-justify-between tw-mx-auto`;
const Race = tw.div`tw-w-[100%] tw-bg-primary-800 tw-mt-4 tw-px-5 tw-p-4 tw-drop-shadow-lg`;
const RaceDetail = tw.div`tw-text-lg tw-font-medium tw-my-auto -tw-mt-3 tw-inline`;
const ChakraButton = tw(Button)`tw-mt-2`;
const RaceName = tw.div`tw-text-xl sm:tw-text-3xl md:tw-text-4xl lg:tw-text-5xl tw-font-black`;

export default ({
  subheading = "",
  heading = "The selected races",
  description = "Below is a list of all the races which are included in the BADTri club championships. Make sure when you enter you put your club exactly as 'Badtri'",
  races = [
    {
      Name: "First Tri",
      Date: "28th April",
      RaceType: "sprint or olympic",
      EntryLink:
        "https://www.letsdothis.com/gb/checkout/ticket?eventId=18753&utm_source=trimax_events&utm_medium=organiser_referral&utm_campaign=preferred&utm_organiser_id=24189",
      Discount: "£15 discount",
    },
    {
      Name: "Cromhall Tri Series",
      Date: "2nd Wednesday (May - September)",
      RaceType: "sprint",
      EntryLink:
        "https://endurancecui.active.com/new/events/87482613/select-race?error=login_required&state=870106eb-fdee-4834-b4dd-dc31c522ba68&_p=48872460290412045",
      Discount: "£5 discount",
    },
    {
      Name: "BADTri Aquathlons",
      Date: "dates tbc",
      RaceType: "run swim",
      Discount: "£5 entry via Spond",
    },
    {
      Name: "Wild Boar Triathlon",
      Date: "16th June",
      RaceType: "olympic or 70.3",
      EntryLink:
        "https://www.letsdothis.com/gb/e/swma-wild-boar-703-olympic-triathlon-2024-193752",
      Discount: "£20 discount",
    },
    {
      Name: "Cotswold Classic",
      Date: "14th July",
      RaceType: "70.3",
      EntryLink: "https://www.113events.com/cotswold-classic/",
      Discount: "discount tbc",
    },
    {
      Name: "Berkeley Triathlon",
      Date: "21st July",
      RaceType: "sprint",
      EntryLink:
        "https://www.britishtriathlon.org/events/Berkeley-Triathlon_15449",
      Discount: "discount tbc",
    },
    {
      Name: "Last Tri",
      Date: "6th October",
      RaceType: "sprint or olympic",
      EntryLink:
        "https://www.letsdothis.com/gb/e/the-last-tri-lydney-19682/race-selection?utm_source=trimax_events&utm_medium=organiser_referral&utm_campaign=preferred&utm_organiser_id=24189&eventId=19682&preferred=true#trimax_events",
      Discount: "£15 discount",
    },
    {
      Name: "Challenge Mallorca",
      Date: "19th October",
      RaceType: "Ironman",
      EntryLink:
        "https://endurancecui.active.com/new/events/87542213/select-race?_p=7050138236311037&e4q=6bad48dc-c744-49a5-8464-a607d3ffbc90&e4p=ceb97099-9c98-4779-9909-439fe493eb12&e4ts=1708626036&e4c=active&e4e=snawe00000000&e4rt=Safetynet&e4h=8f74dfe78fcfe1079f5b4ddd8b06ee78&error=login_required&state=19114f0f-6da0-4843-907b-a1a37681d4a6",
      Discount: "no discount",
    },
  ],
}) => {
  const initialFocusRef = React.useRef();

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <RacesContainer>
          {races.map((race, index) => (
            <Race key={index} tw="tw-z-0">
              <RaceName>{race.Name}</RaceName>
              <>
                <RaceDetail>{race.Date}</RaceDetail> &#x2022;
                <RaceDetail> {race.RaceType}</RaceDetail> &#x2022;{" "}
                <Popover initialFocusRef={initialFocusRef} placement="top">
                  <PopoverTrigger>
                    <RaceDetail tw="tw-underline tw-decoration-dotted tw-cursor-pointer tw-italic">
                      {race.Discount}
                    </RaceDetail>
                  </PopoverTrigger>
                  <PopoverContent
                    color="white"
                    bg="blue.800"
                    borderColor="blue.800"
                  >
                    <PopoverArrow bg="blue.800" />
                    <PopoverCloseButton />
                    <PopoverBody>See spond event for discount code</PopoverBody>
                  </PopoverContent>
                </Popover>
              </>
              <br />
              {race.EntryLink && (
                <ChakraButton
                  onClick={(e) => {
                    window.location.href = race.EntryLink;
                  }}
                  rightIcon={<ExternalLinkIcon />}
                  colorScheme="red"
                >
                  Enter Here
                </ChakraButton>
              )}
            </Race>
          ))}
        </RacesContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
