import React, { useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../misc/Headings.js";
import { PrimaryButton } from "../misc/Buttons.js";
import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-8.svg";

import "slick-carousel/slick/slick.css";

const Container = tw.div`tw-relative`;
const Content = tw.div`tw-max-w-screen-xl tw-mx-auto tw-pb-20 lg:tw-pb-24`;
const TestimonialsContainer = tw.div`tw-mt-16 lg:tw-mt-0`;
const Testimonials = styled.div``;
const Testimonial = tw.div`tw-max-w-md lg:tw-max-w-none tw-mx-auto lg:tw-mx-0 tw-flex tw-flex-col tw-items-center lg:tw-items-stretch lg:tw-flex-row`;

const TestimonialImageSlider = tw(
  Slider,
)`tw-w-full lg:tw-w-5/12 tw-flex-shrink-0 `;
const TestimonialTextSlider = tw(Slider)``;
const TestimonialText = tw.div`tw-outline-none`;

const ImageAndControlContainer = tw.div`tw-relative tw-outline-none`;
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`tw-rounded tw-bg-cover tw-bg-center tw-h-80 sm:tw-h-96 lg:tw-h-144`,
]);

const ControlContainer = tw.div`tw-absolute tw-bottom-0 tw-right-0 tw-bg-gray-100 tw-px-6 tw-py-4 tw-rounded-tl-3xl tw-border`;
const ControlButton = styled(PrimaryButton)`
  ${tw`tw-mx-3 tw-rounded-full tw-text-gray-100 tw-p-2`}
  svg {
    ${tw`tw-w-5 tw-h-5`}
  }
`;

const TextContainer = styled.div((props) => [
  tw`tw-flex tw-flex-col tw-w-full lg:tw-w-7/12`,
  props.textOnLeft
    ? tw`lg:tw-pr-12 lg:tw-order-first`
    : tw`lg:tw-pl-12 lg:tw-order-last`,
]);

const Subheading = tw(SubheadingBase)`tw-mb-4`;
const HeadingTitle = tw(SectionHeading)`lg:tw-text-left tw-leading-tight`;
const Description = tw.p`tw-max-w-md tw-text-center tw-mx-auto lg:tw-mx-0 lg:tw-text-left lg:tw-max-w-none tw-leading-relaxed tw-text-sm sm:tw-text-base lg:tw-text-lg tw-font-medium tw-mt-4 tw-text-secondary-100`;

const QuoteContainer = tw.div`tw-relative tw-mt-10 lg:tw-mt-20`;
const Quote = tw.blockquote`tw-text-left tw-text-sm sm:tw-text-lg lg:tw-text-lg xl:tw-text-2xl`;
const CustomerInfo = tw.div`tw-mt-6 tw-flex tw-flex-col sm:tw-flex-row tw-items-center tw-justify-center lg:tw-justify-start`;
const CustomerProfilePicture = tw.img`tw-rounded-full tw-w-20 tw-h-20`;
const CustomerTextInfo = tw.div`tw-text-center lg:tw-text-left sm:tw-ml-6 tw-mt-2 sm:tw-mt-0`;
const CustomerName = tw.h5`tw-font-semibold tw-text-xl lg:tw-text-2xl xl:tw-text-3xl tw-text-primary-500`;
const CustomerTitle = tw.p`tw-font-medium tw-text-secondary-100`;

const QuotesLeft = tw(
  QuotesLeftIcon,
)`tw-w-6 tw-h-6 tw-opacity-75 tw-text-primary-500 tw-inline-block tw-mr-1 -tw-mt-3`;
const QuotesRight = tw(
  QuotesRightIcon,
)`tw-w-6 tw-h-6 tw-opacity-75 tw-text-primary-500 tw-inline-block tw-ml-1 -tw-mt-3`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`tw-pointer-events-none -tw-z-20 tw-absolute tw-right-0 tw-top-0 tw-h-56 tw-w-56 tw-opacity-15 tw-transform tw-translate-x-2/3 -tw-translate-y-12 tw-text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`tw-pointer-events-none -tw-z-20 tw-absolute tw-left-0 tw-bottom-0 tw-h-64 tw-w-64 tw-opacity-15 tw-transform -tw-translate-x-2/3 tw-text-primary-500`}
`;

export default ({
  subheading = "",
  heading = "Member Spotlight",
  description = "Welcome to our Member Spotlight! We've randomly picked a selection of members to spill the beans on their triathlon journey. Enjoy the tales of triumph, training, and race faux pas!",
  testimonials = null,
  textOnLeft = false,
}) => {
  function shuffle(array) {
    let currentIndex = array.length;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
  }

  /*
   * You can modify the testimonials shown by modifying the array below or passing in the testimonials prop above
   * You can add or remove objects from the array as you need.
   */
  const defaultTestimonials = [
    {
      imageSrc: "/Hilton.webp",
      profileImageSrc: "/Hilton.webp",
      paras: [
        `The South African guy - I’m quite new to the UK and the BADTri group although I’ve been with the club for a year now already having moved over from South Africa in January 2023.`,
        `My Triathlon story; In 2018 I started cycling as a weekend warrior in Johannesburg, slowly but surely getting more committed and competitive, by 2020 I was a ‘cyclist’! Just in time for Covid-19 lockdown and after crashing my bicycle in the first week, of a 3 month hard lockdown, and no means to get my bicycle repaired, I took up running to try maintain my fitness. Having swum in my school years I thought I might as well start swimming again and enter a triathlon. I just wanted to do one, to see if I could do it. I entered a 70.3 Ironman event for June 2021, I got a coach and started training. Then the event was postponed to December ‘21. So I did some Olympic distance events and a few sprints and just kept training. I did the 70.3 and thoroughly enjoyed the challenge but by then I was hooked! Early 2023 I moved to Bristol UK, struggling to find the motivation to train alone I found BADTri through google and joined the group of very welcoming, triathlon addicted people!`,
        `Since joining BADTri I have made some good friends and have enjoyed their company at various training and racing events. The competitive edge has pushed me to become a little quicker and train a lot harder! There’s always something on the go and so many opportunities to get involved with the club activities and meet like minded people!`,
      ],
      customerName: "Hilton",
      customerTitle: "April's member spotlight",
    },
    {
      imageSrc: "/Jenny.webp",
      profileImageSrc: "/Jenny.webp",
      paras: [
        `“You should do a triathlon” said a personal trainer friend to me 11 years ago. I laughed at them. “Don’t be silly. I’ve never swum competitively, just have a cheap hybrid bike and I don’t run”.`,
        `I was very lucky as a child that my parents supported my sporting ambitions, encouraging me to try a range of sports. Badminton became my main sport, training in the England junior squad and competing in the top national county league, but a shoulder injury and subsequent surgery stopped my badminton ambitions to represent GB and compete internationally.`,
        `While the thought of a multi sport event sounded incredibly daunting, and something I had no experience or knowledge of, the suggestion that I could be good at it sparked my competitive side.  A couple of months after “the suggestion”, having worked hard to build up an ability to run, and reminding myself how to ride a bike outdoors, I found myself stood on the start line of The DBMax Chilly Duathlon, nervous about the 2mile run, 10mile cycle and a further 2mile run that lay ahead. I needn’t have been worried, I loved it, and quickly found myself looking for a club where I could learn, train and progress myself from my Halfords hybrid bike to one of those fast fancy bikes that had cruised past me at The Chilly.`,
        `I joined BADTri in 2013 with 2 duathlons and a pool based sprint triathlon under my belt, unable to swim 400m front crawl without stopping and having never cycled more than 20km. Over the past 10 years, with the support of club mates and our lovely coaches, I have competed in many triathlons from local sprints to half ironmans both in the UK and abroad.  The highlights have definitely been though finally achieving my childhood dream to represent GB on the international stage, competing with the GB Age Group team at both European and World Championships.`,
        `Being a member of BADTri has brought me life long friends, including my now husband who proposed at the finish line of a club race at Challenge Almere! From those early days when I didn’t have a clue what I was doing, I now really enjoying giving back to club as a ride leader, helping to organise the annual club aquathon series and encouraging team mates with their own ambitions.`,
      ],
      customerName: "Jenny",
      customerTitle: "March's member spotlight",
    },
    {
      imageSrc: "/Laurence.webp",
      profileImageSrc: "/Laurence.webp",
      paras: [
        `I'm rubbish at team sports and anything with a ball in but as I was always OK at swimming, cycling & running, triathlon was a no brainer for me. I got into triathlon in 1997 and did my first one in 1998 - White Horse Triathlon in Calne. Those days were all about 2 piece tri-suits in fluorescent colours so I was hooked pretty much instantly! Despite several periods of 'time-out' I've kept at it because it keeps me sane and on the straight & narrow.`,
        `Over the years I've taken part in lots of types of events but now I mostly focus on long-distance events since completing my first ironman in 2006. Since then, I've mainly done Ironman and ultra-marathon cycling; I'm not a sprinter but I can keep going for ages! I enjoy competing in Badtri / Bristol Triathlon Club kit as it gives me a sense of belonging.`,
        `For me, sport & training is a large part my social life and it's great to see the club regrouping after Covid with so many new faces joining and getting involved. Racing is fun, but I enjoy training more. Whenever I enter races, I never really aim to win; just to do my best through the best preparation possible, have fun and be around other like-minded people.`,
        `The nature of training for longer events means you often train alone but I do enjoy the Wednesday & Thursday morning swim sessions. Though I think my favourite club event is First Friday Drinks where you can actually have a conversation with someone that lasts more than 15 seconds at the end of the pool lane!`,
        `Badtri has always been the ‘go to’ Bristol Triathlon Club with a friendly and welcoming feel to it. We have amazing people who give their time for the love for our sport. Being part of the club is great for meeting interesting people from all walks of life. Bristol has lots of people moving here so it's a great way for people new to the area to meet new folk.`,
      ],
      customerName: "Laurence",
      customerTitle: "February's member spotlight",
    },
    {
      imageSrc: "/Emma.webp",
      profileImageSrc: "/Emma.webp",
      paras: [
        `Hello! I’m Emma and joined BADTri in 2022. I feel proud to be part of a club that represents a range of people, to include the incredible, fun and strong women of BADTri that I have made wonderful friends with.`,
        `I’m not the fastest or most competitive but the club spirit brings tonnes of fun, whether that’s at a fancy Ironman, the BADTri Aquathons or the local Cromhall Sprint triathlons. Despite my snail pace on a bike, I always have a fabulous time and feel supported and part of something.`,
        `My favourite session is by far the Wednesday morning swim at Horfield, it’s early but the coffee chats and Coach Patrick and HPs smiles make up for that. I also love trail running and Parkrun and there is always a BADTri friend around to join for great company outside of the scheduled sessions.`,
        `I have learnt you’re welcome at BADTri whether you’re a seasoned pro or complete beginner and that’s the most important thing!`,
      ],
      customerName: "Emma",
      customerTitle: "January's member spotlight",
    },
  ];

  if (!testimonials || testimonials.length === 0) {
    testimonials = defaultTestimonials;
  }

  shuffle(testimonials);

  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [imageSliderRef, setImageSliderRef] = useState(null);
  const [textSliderRef, setTextSliderRef] = useState(null);

  return (
    <Container>
      <Content>
        <HeadingInfo
          tw="tw-text-center lg:tw-hidden"
          subheading={subheading}
          heading={heading}
          description={description}
        />
        <TestimonialsContainer>
          <Testimonials>
            <Testimonial>
              <TestimonialImageSlider
                arrows={false}
                ref={setImageSliderRef}
                asNavFor={textSliderRef}
                fade={true}
              >
                {testimonials.map((testimonial, index) => (
                  <ImageAndControlContainer key={index}>
                    <Image imageSrc={testimonial.imageSrc} />
                    <ControlContainer>
                      <ControlButton
                        aria-label="next testimonial"
                        onClick={imageSliderRef?.slickPrev}
                      >
                        <ChevronLeftIcon />
                      </ControlButton>
                      <ControlButton
                        aria-label="previous testimonial"
                        onClick={imageSliderRef?.slickNext}
                      >
                        <ChevronRightIcon />
                      </ControlButton>
                    </ControlContainer>
                  </ImageAndControlContainer>
                ))}
              </TestimonialImageSlider>
              <TextContainer textOnLeft={textOnLeft}>
                <HeadingInfo
                  tw="tw-hidden lg:tw-block"
                  subheading={subheading}
                  heading={heading}
                  description={description}
                />
                <TestimonialTextSlider
                  arrows={false}
                  ref={setTextSliderRef}
                  asNavFor={imageSliderRef}
                  fade={true}
                >
                  {testimonials.map((testimonial, index) => (
                    <TestimonialText key={0}>
                      <QuoteContainer>
                        <Quote>
                          <QuotesLeft />
                          {testimonial.paras.map((para, index) => (
                            <>
                              {index > 0 && (
                                <>
                                  <br></br>
                                  <br></br>
                                </>
                              )}
                              {para}
                            </>
                          ))}
                          <QuotesRight />
                        </Quote>
                      </QuoteContainer>
                      <CustomerInfo>
                        <CustomerProfilePicture
                          src={testimonial.profileImageSrc}
                          alt={testimonial.customerName}
                        />
                        <CustomerTextInfo>
                          <CustomerName>
                            {testimonial.customerName}
                          </CustomerName>
                          <CustomerTitle>
                            {testimonial.customerTitle}
                          </CustomerTitle>
                        </CustomerTextInfo>
                      </CustomerInfo>
                    </TestimonialText>
                  ))}
                </TestimonialTextSlider>
              </TextContainer>
            </Testimonial>
          </Testimonials>
        </TestimonialsContainer>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

const HeadingInfo = ({ subheading, heading, description, ...props }) => (
  <div {...props}>
    {subheading ? <Subheading>{subheading}</Subheading> : null}
    <HeadingTitle>{heading}</HeadingTitle>
    <Description>{description}</Description>
  </div>
);
