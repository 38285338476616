import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import {
  NavLink,
  NavLinks,
} from "components/navigationLinks/NavigationLinks.styles.js";

export const Header = tw.header`
  tw-flex tw-justify-between tw-items-center
  tw-max-w-screen-xl tw-mx-auto
`;

export const LogoLink = styled(NavLink)`
  ${tw`tw-flex tw-items-center tw-font-black tw-border-b-0 tw-text-2xl! tw-ml-0!`};

  img {
    ${tw`tw-w-10 tw-mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`tw-flex tw-flex-1 tw-items-center tw-justify-between`;
export const NavToggle = tw.button`
  lg:tw-hidden tw-z-20 focus:tw-outline-none hocus:tw-text-primary-500 tw-transition tw-duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:tw-hidden tw-z-10 tw-fixed tw-top-0 tw-inset-x-0 tw-mx-4 tw-my-6 tw-p-8 tw-border tw-text-center tw-rounded-lg tw-text-gray-900 tw-bg-white`}
  ${NavLinks} {
    ${tw`tw-flex tw-flex-col tw-items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
 tw-hidden lg:tw-flex tw-flex-1 tw-justify-between tw-items-center
`;

export const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:tw-hidden`,
    desktopNavLinks: tw`sm:tw-flex`,
    mobileNavLinksContainer: tw`sm:tw-hidden`,
  },
  md: {
    mobileNavLinks: tw`md:tw-hidden`,
    desktopNavLinks: tw`md:tw-flex`,
    mobileNavLinksContainer: tw`md:tw-hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:tw-hidden`,
    desktopNavLinks: tw`lg:tw-flex`,
    mobileNavLinksContainer: tw`lg:tw-hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:tw-hidden`,
    desktopNavLinks: tw`lg:tw-flex`,
    mobileNavLinksContainer: tw`lg:tw-hidden`,
  },
};
