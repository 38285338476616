// eslint-disable-next-line no-unused-vars
import tw from "twin.macro"; //KEEP so you can add tw={} to components

import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { ReactComponent as AddIcon } from "feather-icons/dist/icons/plus.svg";
import { BeatLoader } from "react-spinners";
import SessionForm from "components/sessionForm/SessionForm";

export default ({ sessionDetails, upsertSessions }) => {
  const [newSession, setNewSession] = useState(sessionDetails);
  const [shouldCheckInvalidity, setShouldCheckInvalidity] = useState(false);

  const sessionNameValid = newSession.title === "" || !newSession.title;
  const sessionLocationValid =
    newSession.location === "" || !newSession.location;
  const sessionTimeValid = newSession.time?.length !== 5;
  const sessionPriceValid = !(
    newSession.price?.[0] === "£" || newSession.price === "FREE"
  );
  const sessionDescriptionValid =
    newSession.description === "" || !newSession.description;

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        isLoading={false}
        spinner={<BeatLoader size={8} color="white" />}
        colorScheme="yellow"
        rightIcon={<AddIcon />}
        onClick={onOpen}
        size="sm"
      >
        Add
      </Button>

      <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Activity</ModalHeader>
          <ModalCloseButton />
          <SessionForm
            sessionDetails={sessionDetails}
            newSession={newSession}
            setNewSession={setNewSession}
            shouldCheckInvalidity={shouldCheckInvalidity}
            sessionNameValid={sessionNameValid}
            sessionLocationValid={sessionLocationValid}
            sessionTimeValid={sessionTimeValid}
            sessionPriceValid={sessionPriceValid}
            sessionDescriptionValid={sessionDescriptionValid}
          />
          <ModalFooter>
            <Button tw="tw-mr-2" variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                setShouldCheckInvalidity(true);

                if (
                  sessionNameValid ||
                  sessionLocationValid ||
                  sessionTimeValid ||
                  sessionPriceValid ||
                  sessionDescriptionValid
                ) {
                  return;
                }

                upsertSessions(newSession);
                onClose();
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
