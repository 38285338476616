import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import colors from "tailwindcss/colors";
import { css } from "styled-components/macro";
import { Button } from "@chakra-ui/react";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as DollarIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as ClockIcon } from "feather-icons/dist/icons/clock.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const Container = tw.div`tw-relative tw-w-screen -tw-translate-x-8`;
const SliderContainer = tw.div`tw-relative tw-px-4 sm:tw-px-0 -tw-mt-12 tw-mb-10`;
const Content = tw.div`tw-max-w-screen-xl tw-mx-auto tw-py-16 lg:tw-py-20 lg:tw-px-12`;

const CardType1 = tw.div`tw-mt-12 tw-w-full sm:tw-w-1/2 lg:tw-w-1/3 tw-flex tw-flex-col tw-items-center`;
const Cards = tw.div`tw-flex tw-flex-wrap tw-flex-row tw-justify-center sm:tw-max-w-2xl lg:tw-max-w-5xl tw-mx-auto`;
const CardImage = styled.div`
  ${(props) => css`
    background-image: url("${props.imageSrc}");
  `}
  ${tw`tw-w-64 tw-h-64 tw-bg-cover tw-bg-center tw-rounded`}
`;
const CardContent = styled.div`
  ${tw`tw-flex tw-flex-col tw-items-center tw-mt-6`}
  .position {
    ${tw`tw-uppercase tw-font-bold tw-tracking-widest tw-text-xs tw-text-primary-500`}
  }
  .name {
    ${tw`tw-mt-1 tw-text-xl tw-font-medium tw-text-gray-900`}
  }
`;

const StyledButton = tw(Button)`tw-mx-auto tw-mt-5 tw-block`;
const HeadingWithControl = tw.div` tw-px-8 sm:tw-px-0 tw-text-center`;
const Heading = tw(SectionHeading)`tw-text-center`;
const Paragraph = tw.p`tw-text-center lg:tw-my-8 tw-text-sm lg:tw-text-base tw-font-medium tw-text-gray-600 tw-max-w-2xl tw-mx-auto`;
const Controls = tw.div`tw-flex tw-items-center tw-absolute tw-w-full tw-h-full tw-top-0`;

const ControlButtonStyling = tw.button`tw-h-full tw-px-8 tw-py-3 tw-font-bold tw-rounded-2xl tw-text-gray-900 hocus:tw-bg-gray-100 focus:tw-outline-none tw-transition tw-duration-300`;

const ControlButton = styled(ControlButtonStyling)`
  ${tw`tw-mt-4 sm:tw-mt-0 tw-p-2 tw-h-full tw-hidden lg:tw-block`}
  svg {
    ${tw`tw-w-8 tw-h-8`}
  }
`;
const PrevButton = tw(
  ControlButton,
)`tw-mr-auto lg:-tw-translate-x-12 md:-tw-translate-x-3 sm:tw-translate-x-12`;
const NextButton = tw(
  ControlButton,
)`tw-ml-auto lg:tw-translate-x-12 md:tw-translate-x-3 sm:-tw-translate-x-12`;

const CardSlider = styled(Slider)`
  ${tw`tw-mt-16 tw-overflow-hidden`}
  .slick-track {
    ${tw`tw-flex`}
  }
  .slick-slide {
    ${tw`tw-h-auto tw-flex tw-justify-center tw-mb-1`}
  }
  .slick-list {
    overflow: tw-hidden;
  }
`;

const Dots = tw.span`tw-w-1 tw-h-1 first:tw-bg-gray-500 tw-bg-gray-200 tw-rounded-full first:tw-ml-auto last:tw-mr-auto`;

const CardType2 = tw.div`tw-bg-gray-200 tw-w-full tw-h-auto tw-flex! tw-flex-col tw-border tw-max-w-sm tw-rounded-2xl tw-relative focus:tw-outline-none tw-px-4 sm:tw-px-0`;
const HorizontalLine = tw.hr`-tw-mr-4 -tw-ml-4 sm:tw-mr-0 sm:tw-ml-0 tw-border-gray-400`;
const TextInfo = tw.div`tw-py-6 sm:tw-px-10 sm:tw-py-6`;
const TextInfoTop = tw.div`tw-py-6 sm:tw-px-10 sm:tw-py-6 tw-bg-primary-900 tw-text-gray-100 tw-rounded-t-2xl -tw-ml-4 -tw-mr-4 tw-pr-4 tw-pl-4 sm:tw-ml-0 sm:tw-mr-0`;
const TitleReviewContainer = tw.div`tw-flex tw-flex-row sm:tw-justify-between tw-items-center tw-mb-6`;
const TitleReviewContainerTop = tw.div`tw-flex tw-flex-row sm:tw-justify-between tw-items-center`;
const Title = tw.h5`tw-text-2xl tw-font-bold`;
const SubTitle = tw.h6`tw-text-lg tw-font-bold`;
const Description = tw.p`tw-text-sm tw-leading-loose tw-mt-2 sm:tw-mt-4`;
const SecondaryInfoContainer = tw.div`tw-mt-2 sm:tw-mt-4`;
const IconWithText = tw.div`tw-flex tw-items-end tw-mr-6 tw-my-2`;
const Pill = tw.div`tw-text-xs tw-font-bold tw-px-3 tw-rounded tw-py-2 tw-uppercase tw-bg-primary-400 tw-text-gray-100 tw-leading-none sm:tw-mt-0 sm:tw-w-auto tw-text-center tw-ml-auto`;
const IconContainer = styled.div`
  ${tw`tw-inline-block tw-rounded-full tw-p-2 tw-bg-gray-700 tw-text-gray-100`}
  svg {
    ${tw`tw-w-3 tw-h-3`}
  }
`;
const Text = tw.div`tw-ml-2 tw-text-sm tw-font-semibold tw-text-gray-800 tw-mb-auto tw-mt-auto`;

export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const [highlightedDot, setHighlightedDot] = useState(1);

  const sessionSliderSettings = {
    infinite: true,
    arrows: false,
    dots: true,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div style={{ textAlign: "center" }}>
        <ul style={{ display: "inline-flex", gap: "5px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <Dots
        style={{
          display: "inline-flex",
          flexDirection: "col",
          fontSize: "0",
        }}
      ></Dots>
    ),
  };

  const sliderSettings = {
    infinite: true,
    arrows: false,
    dots: true,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div style={{ textAlign: "center" }}>
        <StyledButton
          style={{ display: "block" }}
          colorScheme="red"
          rightIcon={<ExternalLinkIcon />}
          variant="ghost"
          onClick={() => {
            document.location.href = "/coaches";
          }}
        >
          All Coaches and Committee
        </StyledButton>
        <ul style={{ display: "inline-flex", gap: "5px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <Dots
        style={{
          display: "inline-flex",
          flexDirection: "col",
          fontSize: "0",
        }}
      ></Dots>
    ),
  };

  const coaches = [
    {
      imageSrc: "/MariaPowell.jpg",
      position1: "BTF Level 2 Triathlon Coach",
      name: "Maria Powell",
    },
    {
      imageSrc: "/PatrickNewman.jpeg",
      position1: "BTF Level 2 Triathlon Coach",
      position2: "ASA Level 2 Swim Teacher",
      name: "Patrick Newman",
    },
    {
      imageSrc: "/HannahBizoumis.jpg",
      position1: "Personal Trainer Level 3",
      position2: "Ironman Triathlon Coach",
      name: "Hannah Bizoumis",
    },
    {
      imageSrc: "/HansPeterKiekuth.jpeg",
      position1: "BTF Level 2 Triathlon Coach",
      name: "Hans-Peter Kiekuth",
    },
    {
      imageSrc: "/JennyCampbellRitchie.jpg",
      position1: "Ride Leader",
      name: "Jenny Campbell-Ritchie",
    },
    {
      imageSrc: "/AndyParsons.jpeg",
      position1: "BTF Level 2 Triathlon Coach",
      position2: "England Athletics - Running Coach",
      name: "Andy Parsons",
    },
    {
      imageSrc: "/EmmaAllum.jpg",
      position1: "BTF Level 1 Triathlon Coach",
      name: "Emma Allum",
    },
    {
      imageSrc: "/JonWaring.jpeg",
      position1: "BTF Level 2 Triathlon Coach",
      name: "Jon Waring",
    },
    {
      imageSrc: "/KateRoss.jpg",
      position1: "BTF Level 2 Triathlon Coach",
      position2: "BSc Sports, Fitness and Coaching",
      name: "Kate Ross",
    },
    {
      imageSrc: "/BenHolding.jpeg",
      position1: "BTF Level 2 Triathlon Coach",
      position2: "Previously an ASA Level 3 Swim Teacher",
      name: "Ben Holding",
    },
    {
      imageSrc: "/BruceWillerton.jpg",
      position1: "BTF Level 2 Triathlon Coach",
      name: "Bruce Willerton",
    },
    {
      imageSrc: "/ChrisHornung.jpg",
      position1: "BTF Level 2 Triathlon Coach",
      name: "Chris Hornung",
    },
    {
      imageSrc: "/JamieHinton.jpg",
      position1: "BTF Level 1 Triathlon Coach",
      name: "Jamie Hinton",
    },
  ];

  return (
    <SliderContainer>
      <CardSlider ref={setSliderRef} {...sliderSettings}>
        {coaches.map((card, index) => (
          <CardType1 key={index}>
            <CardImage imageSrc={card.imageSrc} />
            <CardContent>
              <span className="position">{card.position1}</span>
              <span className="position">{card.position2}</span>
              <span className="name">{card.name}</span>
            </CardContent>
          </CardType1>
        ))}
      </CardSlider>
    </SliderContainer>
  );
};
