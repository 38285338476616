import React, { useState, useEffect } from "react";
import colors from "tailwindcss/colors";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "components/header/Header.js";
import { ContentWithPaddingXl } from "components/misc/Layouts";
import { GetCookie, IsAdult } from "api/jwt.js";
import { DeleteMember, GetAllMembers, MakeJuniorAdult } from "api/secureApi.ts";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  useToast,
  Button,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialog,
  Link,
} from "@chakra-ui/react";
import {
  ActionCentreContainer,
  ActionContainerAmber,
  ActionContainerHead,
  ActionContainerRed,
  AlertIconAmber,
  AlertIconRed,
  AlertIconWhite,
  AlertText,
  CheveronDownButton,
  CheveronUpButton,
  ColumnInformationContainer,
  FieldContainer,
  FieldHeading,
  FieldValue,
  FilterContainer,
  FilterOption,
  HR,
  Heading,
  LoadingTableSuperContainer,
  PillGreen,
  PillRed,
  PillYellow,
  SearchIcon,
  SearchInput,
  SearchInputContainer,
  SecondaryTableBodyCell,
  SecondaryTableHeaderCell,
  TD,
  TD100,
  Table,
  TableBody,
  TableBodyCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableSuperContainer,
} from "./membershipDatabase.styles";

const EXPIRING_MEMBER_ALERT = "EXPIRING_MEMBER_ALERT";
const EXPIRING_COACH_ALERT = "EXPIRING_COACH_ALERT";
const EXPIRED_COACH_ALERT = "EXPIRED_COACH_ALERT";
const BECAME_SENIOR_ALERT = "BECAME_SENIOR_ALERT";

const dateDisplayOptions = {
  day: "numeric",
  month: "long",
  year: "numeric",
};

export default () => {
  const toast = useToast();
  const [isActionsExpanded, setIsActionsExpanded] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const [alerts, setAlerts] = useState([]);
  const [members, setMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [groupChangeLoading, setGroupChangeLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isGroupChangeOpen,
    onOpen: onGroupChangeOpen,
    onClose: onGroupChangeClose,
  } = useDisclosure();
  const cancelRef = React.useRef();
  const btnRef = React.useRef();
  const [focussedMember, setFocussedMember] = useState(null);

  useEffect(() => {
    var deletedRedirect = getParameterByName("deleted");
    if (deletedRedirect != null) {
      toast({
        title: deletedRedirect + " has been deleted!",
        status: "success",
        isClosable: true,
      });

      var currentURL = window.location.href;
      var myNewURL = currentURL.split("?")[0];
      window.history.pushState("", "", myNewURL);
    }

    const idToken = GetCookie("idToken");
    GetAllMembers(idToken)
      .then((response) => {
        setIsLoading(false);
        setAlerts(calculateAlerts(response));
        setMembers(response);
        setFilteredMembers(response);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [toast]);

  const calculateAlerts = function (members) {
    const alerts = [];
    members.forEach((member) => {
      if (member.joinedAsJunior && IsAdult(member.dateOfBirth)) {
        alerts.push({
          member: member,
          alertType: BECAME_SENIOR_ALERT,
        });
      }
      if (member.coachStatus === "EXPIRING") {
        alerts.push({
          member: member,
          alertType: EXPIRING_COACH_ALERT,
        });
      }
      if (member.coachStatus === "EXPIRED") {
        alerts.push({
          member: member,
          alertType: EXPIRED_COACH_ALERT,
        });
      }
      if (member.membershipStatus === "EXPIRED") {
        alerts.push({
          member: member,
          alertType: EXPIRING_MEMBER_ALERT,
        });
      }
    });
    return alerts;
  };

  const onActionContainerClick = function () {
    if (isActionsExpanded) {
      document.getElementById("actions-container").style.display = "none";
      document.getElementById("down-cheveron").style.display = "";
      document.getElementById("up-cheveron").style.display = "none";
    } else {
      document.getElementById("actions-container").style.display = "";
      document.getElementById("up-cheveron").style.display = "block";
      document.getElementById("down-cheveron").style.display = "none";
    }

    setIsActionsExpanded(!isActionsExpanded);
  };

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  const onSearch = function (searchTermEvent) {
    const searchTerm = searchTermEvent.target.value;

    const filteredMembersList = [];

    if (searchTerm === "") {
      setFilteredMembers(members);
      return;
    }

    for (let memberIndex in members) {
      const member = members[memberIndex];
      if (("" + member.membershipNumber).includes(searchTerm)) {
        filteredMembersList.push(member);
      } else if (
        (
          member.firstName.toLowerCase() +
          " " +
          member.familyName.toLowerCase()
        ).includes(searchTerm.toLowerCase())
      ) {
        filteredMembersList.push(member);
      }
    }
    setFilteredMembers(filteredMembersList);
  };

  const isCoach = function (member) {
    return member.isCoach || member.isActivator;
  };

  const isAdmin = function (member) {
    return member.isAdmin;
  };

  const isJunior = function (member) {
    return member.joinedAsJunior;
  };

  const isSenior = function (member) {
    return !member.joinedAsJunior;
  };

  const mostRecent = function (memberA, memberB) {
    const a = new Date(memberA.createdAt);
    const b = new Date(memberB.createdAt);
    return -(a - b);
  };

  const onAllFilterClick = function () {
    setFilteredMembers(members);

    const self = document.getElementById("allMembersFilter");
    const allCoachesButton = document.getElementById("allCoachesFilter");
    const allAdminsButton = document.getElementById("allAdminsFilter");
    const allJuniorsFilter = document.getElementById("allJuniorsFilter");
    const mostRecentFilter = document.getElementById("mostRecentFilter");
    const allSeniorsFilter = document.getElementById("allSeniorsFilter");

    makeFilterHighligted(self);
    removeFilterhighlighting(allCoachesButton);
    removeFilterhighlighting(allAdminsButton);
    removeFilterhighlighting(allJuniorsFilter);
    removeFilterhighlighting(mostRecentFilter);
    removeFilterhighlighting(allSeniorsFilter);
  };

  const mostRecentFilterClick = function () {
    setFilteredMembers([...members].sort(mostRecent));

    const allMembersFilter = document.getElementById("allMembersFilter");
    const allCoachesFilter = document.getElementById("allCoachesFilter");
    const allAdminsFilter = document.getElementById("allAdminsFilter");
    const allJuniorsFilter = document.getElementById("allJuniorsFilter");
    const self = document.getElementById("mostRecentFilter");
    const allSeniorsFilter = document.getElementById("allSeniorsFilter");

    makeFilterHighligted(self);
    removeFilterhighlighting(allMembersFilter);
    removeFilterhighlighting(allAdminsFilter);
    removeFilterhighlighting(allJuniorsFilter);
    removeFilterhighlighting(allCoachesFilter);
    removeFilterhighlighting(allSeniorsFilter);
  };

  const onSeniorsFilterClick = function () {
    setFilteredMembers(members.filter(isSenior));

    const allMembersFilter = document.getElementById("allMembersFilter");
    const allCoachesFilter = document.getElementById("allCoachesFilter");
    const allAdminsFilter = document.getElementById("allAdminsFilter");
    const allJuniorsFilter = document.getElementById("allJuniorsFilter");
    const mostRecentFilter = document.getElementById("mostRecentFilter");
    const self = document.getElementById("allSeniorsFilter");

    makeFilterHighligted(self);
    removeFilterhighlighting(allMembersFilter);
    removeFilterhighlighting(allAdminsFilter);
    removeFilterhighlighting(allJuniorsFilter);
    removeFilterhighlighting(allCoachesFilter);
    removeFilterhighlighting(mostRecentFilter);
  };

  const onCoachesFilterClick = function () {
    setFilteredMembers(members.filter(isCoach));

    const allMembersFilter = document.getElementById("allMembersFilter");
    const self = document.getElementById("allCoachesFilter");
    const allAdminsButton = document.getElementById("allAdminsFilter");
    const allJuniorsFilter = document.getElementById("allJuniorsFilter");
    const mostRecentFilter = document.getElementById("mostRecentFilter");
    const allSeniorsFilter = document.getElementById("allSeniorsFilter");

    makeFilterHighligted(self);
    removeFilterhighlighting(allMembersFilter);
    removeFilterhighlighting(allAdminsButton);
    removeFilterhighlighting(allJuniorsFilter);
    removeFilterhighlighting(mostRecentFilter);
    removeFilterhighlighting(allSeniorsFilter);
  };

  const onAdminFilterClick = function () {
    setFilteredMembers(members.filter(isAdmin));

    const allMembersFilter = document.getElementById("allMembersFilter");
    const allCoachesButton = document.getElementById("allCoachesFilter");
    const allJuniorsFilter = document.getElementById("allJuniorsFilter");
    const mostRecentFilter = document.getElementById("mostRecentFilter");
    const allSeniorsFilter = document.getElementById("allSeniorsFilter");
    const self = document.getElementById("allAdminsFilter");

    makeFilterHighligted(self);
    removeFilterhighlighting(allCoachesButton);
    removeFilterhighlighting(allMembersFilter);
    removeFilterhighlighting(allJuniorsFilter);
    removeFilterhighlighting(mostRecentFilter);
    removeFilterhighlighting(allSeniorsFilter);
  };

  const onJuniorsFilterClick = function () {
    setFilteredMembers(members.filter(isJunior));

    const allMembersFilter = document.getElementById("allMembersFilter");
    const allCoachesButton = document.getElementById("allCoachesFilter");
    const allAdminsButton = document.getElementById("allAdminsFilter");
    const mostRecentFilter = document.getElementById("mostRecentFilter");
    const allSeniorsFilter = document.getElementById("allSeniorsFilter");
    const self = document.getElementById("allJuniorsFilter");

    makeFilterHighligted(self);
    removeFilterhighlighting(allCoachesButton);
    removeFilterhighlighting(allMembersFilter);
    removeFilterhighlighting(allAdminsButton);
    removeFilterhighlighting(mostRecentFilter);
    removeFilterhighlighting(allSeniorsFilter);
  };

  const makeFilterHighligted = function (component) {
    component.style.borderColor = colors.gray[900];
    component.style.backgroundColor = colors.gray[100];
  };

  const removeFilterhighlighting = function (component) {
    component.style.borderColor = colors.gray[300];
    component.style.backgroundColor = "white";
  };

  const viewMember = function (membershipId) {
    document.location.href = "/member?id=" + membershipId;
  };

  window.onclick = function (event) {
    if (!event.target.matches(".table-alert-button")) {
      var dropdowns = document.getElementsByClassName("drop-down-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.style.display === "block") {
          openDropdown.style.display = "none";
        }
      }
    }
  };

  return (
    <>
      <AnimationRevealPage>
        <Header />
        <ContentWithPaddingXl>
          <Breadcrumb
            spacing="8px"
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/profile">My Account</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink>Membership Database</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Heading>Membership Database</Heading>

          {alerts.length !== 0 && (
            <ActionCentreContainer>
              <ActionContainerHead onClick={onActionContainerClick}>
                <TD>
                  <AlertIconWhite></AlertIconWhite>
                </TD>
                <TD100>
                  <AlertText>ITEMS REQUIRE YOUR ATTENTION</AlertText>
                </TD100>
                <TD>
                  <CheveronDownButton id="down-cheveron"></CheveronDownButton>
                  <CheveronUpButton id="up-cheveron"></CheveronUpButton>
                </TD>
              </ActionContainerHead>
              <div id="actions-container" style={{ display: "none" }}>
                {alerts.map((alert) => (
                  <>
                    {alert.alertType === EXPIRING_MEMBER_ALERT && (
                      <ActionContainerRed>
                        <TD>
                          <AlertIconRed></AlertIconRed>
                        </TD>
                        <TD100>
                          <AlertText>
                            Member{" "}
                            <Link
                              tw="tw-cursor-pointer"
                              color="teal.500"
                              onClick={(e) => {
                                setFocussedMember(alert.member);
                                onOpen(e);
                              }}
                            >
                              #{alert.member.membershipNumber}'s
                            </Link>{" "}
                            memebrship has expired. Remove from Spond.
                          </AlertText>
                        </TD100>
                        <TD>
                          <Button
                            onClick={(e) => {
                              setFocussedMember(alert.member);
                              onDeleteOpen(e);
                            }}
                            colorScheme="red"
                          >
                            DONE
                          </Button>
                        </TD>
                      </ActionContainerRed>
                    )}
                    {alert.alertType === BECAME_SENIOR_ALERT && (
                      <ActionContainerRed>
                        <TD>
                          <AlertIconRed></AlertIconRed>
                        </TD>
                        <TD100>
                          <AlertText>
                            Member{" "}
                            <Link
                              tw="tw-cursor-pointer"
                              color="teal.500"
                              onClick={(e) => {
                                setFocussedMember(alert.member);
                                onOpen(e);
                              }}
                            >
                              #{alert.member.membershipNumber}
                            </Link>{" "}
                            has become a senior, remove them from the juniors
                            spond group.
                          </AlertText>
                        </TD100>
                        <TD>
                          <Button
                            onClick={(e) => {
                              setFocussedMember(alert.member);
                              onGroupChangeOpen(e);
                            }}
                            colorScheme="red"
                          >
                            DONE
                          </Button>
                        </TD>
                      </ActionContainerRed>
                    )}
                    {alert.alertType === EXPIRED_COACH_ALERT && (
                      <ActionContainerRed>
                        <TD>
                          <AlertIconRed></AlertIconRed>
                        </TD>
                        <TD100>
                          <AlertText>
                            Coach{" "}
                            <Link
                              tw="tw-cursor-pointer"
                              color="teal.500"
                              onClick={(e) => {
                                setFocussedMember(alert.member);
                                onOpen(e);
                              }}
                            >
                              #{alert.member.membershipNumber}
                            </Link>{" "}
                            has an expired certificate.
                          </AlertText>
                        </TD100>
                        <TD></TD>
                      </ActionContainerRed>
                    )}
                    {alert.alertType === EXPIRING_COACH_ALERT && (
                      <ActionContainerAmber>
                        <TD>
                          <AlertIconAmber></AlertIconAmber>
                        </TD>
                        <TD100>
                          <AlertText>
                            Coach{" "}
                            <Link
                              tw="tw-cursor-pointer"
                              color="teal.500"
                              onClick={(e) => {
                                setFocussedMember(alert.member);
                                onOpen(e);
                              }}
                            >
                              #{alert.member.membershipNumber}
                            </Link>{" "}
                            has a certificate, which is due to expire.
                          </AlertText>
                        </TD100>
                        <TD></TD>
                      </ActionContainerAmber>
                    )}
                  </>
                ))}
              </div>
            </ActionCentreContainer>
          )}

          <FilterContainer>
            <FilterOption id="allMembersFilter" onClick={onAllFilterClick}>
              All
            </FilterOption>
            <FilterOption id="mostRecentFilter" onClick={mostRecentFilterClick}>
              Recent
            </FilterOption>
            <FilterOption id="allCoachesFilter" onClick={onCoachesFilterClick}>
              Coaches
            </FilterOption>
            <FilterOption id="allAdminsFilter" onClick={onAdminFilterClick}>
              Admins
            </FilterOption>
            <FilterOption id="allJuniorsFilter" onClick={onJuniorsFilterClick}>
              Juniors
            </FilterOption>
            <FilterOption id="allSeniorsFilter" onClick={onSeniorsFilterClick}>
              Seniors
            </FilterOption>
          </FilterContainer>
          <SearchInputContainer>
            <SearchInput
              placeholder="Who are you looking for?"
              onChange={onSearch}
            />
            <SearchIcon></SearchIcon>
          </SearchInputContainer>

          {loading && <LoadingTableSuperContainer></LoadingTableSuperContainer>}
          {!loading && (
            <TableSuperContainer>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell></TableHeaderCell>
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell>Membership Status</TableHeaderCell>
                    <SecondaryTableHeaderCell>
                      Coach Status
                    </SecondaryTableHeaderCell>
                    <SecondaryTableHeaderCell>
                      Joining Date
                    </SecondaryTableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredMembers.map((member, index) => (
                    <TableRow
                      onClick={() => {
                        setFocussedMember(member);
                        onOpen();
                      }}
                    >
                      <TableBodyCell>{member.membershipNumber}</TableBodyCell>
                      <TableBodyCell>
                        {member.firstName} {member.familyName}
                      </TableBodyCell>
                      {member.membershipStatus === "ACTIVE" && (
                        <TableBodyCell>
                          <PillGreen>Active</PillGreen>
                        </TableBodyCell>
                      )}
                      {member.membershipStatus === "EXPIRING" && (
                        <TableBodyCell>
                          <PillYellow>Expiring</PillYellow>
                        </TableBodyCell>
                      )}
                      {(member.membershipStatus === "NEW" ||
                        member.membershipStatus === "EXPIRED") && (
                        <TableBodyCell>
                          <PillRed>{member.membershipStatus}</PillRed>
                        </TableBodyCell>
                      )}
                      {(member.isCoach || member.isActivator) &&
                        member.coachStatus === "ACTIVE" && (
                          <SecondaryTableBodyCell>
                            <PillGreen>Active</PillGreen>
                          </SecondaryTableBodyCell>
                        )}
                      {(member.isCoach || member.isActivator) &&
                        member.coachStatus === "EXPIRED" && (
                          <SecondaryTableBodyCell>
                            <PillRed>EXPIRED</PillRed>
                          </SecondaryTableBodyCell>
                        )}
                      {(member.isCoach || member.isActivator) &&
                        member.coachStatus === "NEW" && (
                          <SecondaryTableBodyCell>
                            <PillRed>NEW</PillRed>
                          </SecondaryTableBodyCell>
                        )}
                      {(member.isCoach || member.isActivator) &&
                        member.coachStatus === "EXPIRING" && (
                          <SecondaryTableBodyCell>
                            <PillYellow>EXPIRING</PillYellow>
                          </SecondaryTableBodyCell>
                        )}
                      {!(member.isCoach || member.isActivator) && (
                        <SecondaryTableBodyCell></SecondaryTableBodyCell>
                      )}
                      <SecondaryTableBodyCell>
                        {new Date(member.createdAt).toLocaleDateString(
                          "en-GB",
                          dateDisplayOptions,
                        )}
                      </SecondaryTableBodyCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableSuperContainer>
          )}
        </ContentWithPaddingXl>
        <Footer />
      </AnimationRevealPage>
      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Member
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete {focussedMember?.firstName}{" "}
              {focussedMember?.familyName} (Member #
              {focussedMember?.membershipNumber})? This action cannot be undone.
              Make sure you have already removed this member from Spond.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteClose}>
                Cancel
              </Button>
              <Button
                isLoading={deleteLoading}
                spinnerPlacement="start"
                colorScheme="red"
                onClick={() => {
                  setDeleteLoading(true);
                  const idToken = GetCookie("idToken");
                  DeleteMember(idToken, focussedMember.membershipNumber)
                    .then(() => {
                      onDeleteClose();
                      toast({
                        title: "Succesfully deleted member!",
                        status: "success",
                        isClosable: true,
                      });
                    })
                    .catch((err) => {
                      setDeleteLoading(false);
                      toast({
                        title: "Something went wrong - try again!",
                        status: "error",
                        isClosable: true,
                      });
                    });
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={isGroupChangeOpen}
        leastDestructiveRef={cancelRef}
        onClose={onGroupChangeClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Update Member
            </AlertDialogHeader>

            <AlertDialogBody>
              Have you confirmed {focussedMember?.firstName}{" "}
              {focussedMember?.familyName} (Member #
              {focussedMember?.membershipNumber}) is no longer part of the
              juniors Spond group?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onGroupChangeClose}>
                Cancel
              </Button>
              <Button
                isLoading={groupChangeLoading}
                spinnerPlacement="start"
                colorScheme="red"
                onClick={() => {
                  setGroupChangeLoading(true);
                  const idToken = GetCookie("idToken");
                  MakeJuniorAdult(idToken, focussedMember.membershipNumber)
                    .then(() => {
                      onGroupChangeClose();
                      toast({
                        title: "Succesfully updated junior to senior member!",
                        status: "success",
                        isClosable: true,
                      });
                    })
                    .catch((err) => {
                      setGroupChangeLoading(false);
                      toast({
                        title: "Something went wrong - try again!",
                        status: "error",
                        isClosable: true,
                      });
                    });
                }}
                ml={3}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size={"lg"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Basic Details</DrawerHeader>

          <DrawerBody>
            {focussedMember != null && (
              <>
                <ColumnInformationContainer>
                  <FieldContainer>
                    <FieldHeading>First Name</FieldHeading>
                    <FieldValue>{focussedMember.firstName}</FieldValue>
                  </FieldContainer>
                  <FieldContainer>
                    <FieldHeading>Family Name</FieldHeading>
                    <FieldValue>{focussedMember.familyName}</FieldValue>
                  </FieldContainer>
                  <FieldContainer>
                    <FieldHeading>Date of Birth</FieldHeading>
                    <FieldValue>
                      {new Date(focussedMember.dateOfBirth).toLocaleDateString(
                        "en-GB",
                        dateDisplayOptions,
                      )}
                    </FieldValue>
                  </FieldContainer>
                  <FieldContainer>
                    <FieldHeading>Email Address</FieldHeading>
                    <FieldValue>{focussedMember.emailAddress}</FieldValue>
                  </FieldContainer>
                  <FieldContainer>
                    <FieldHeading>Gender</FieldHeading>
                    <FieldValue>{focussedMember.gender}</FieldValue>
                  </FieldContainer>
                </ColumnInformationContainer>
                <HR></HR>
                <ColumnInformationContainer>
                  <FieldContainer>
                    <FieldHeading>Joining Date</FieldHeading>
                    <FieldValue>
                      {new Date(focussedMember.createdAt).toLocaleDateString(
                        "en-GB",
                        dateDisplayOptions,
                      )}
                    </FieldValue>
                  </FieldContainer>
                </ColumnInformationContainer>
              </>
            )}
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="red"
              onClick={(e) => {
                viewMember(focussedMember.membershipNumber);
              }}
            >
              View
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
