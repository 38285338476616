import React, { useState } from "react";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings";
import { ReactComponent as DeleteButtonIcon } from "feather-icons/dist/icons/trash-2.svg";

import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { ReactComponent as AwardIcon } from "feather-icons/dist/icons/award.svg";
import { ReactComponent as RevokeIcon } from "feather-icons/dist/icons/x-square.svg";
import { ReactComponent as AddIcon } from "feather-icons/dist/icons/user-plus.svg";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import {
  AwardMembership,
  DeleteMember,
  MakeAdmin,
  MakeCoach,
} from "api/secureApi.ts";
import { GetCookie } from "api/jwt";
import {
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Skeleton,
  useDisclosure,
  AlertDialog,
} from "@chakra-ui/react";
import MemberDetails from "./MemberDetails";
import CoachesInfo from "./CoachesInfo";

mapboxgl.accessToken =
  "pk.eyJ1IjoibWFya2Fsc3RvbiIsImEiOiJjangzYWt0ZHYwMTdzNDlrdDhtMHl0a3MyIn0.LWzTJORvF1PLlosFIAEyjQ";

const ColumnInformationContainer = tw.div`tw-relative md:tw-grid tw-w-full md:tw-grid-cols-2`;
const SpondGroupContainer = tw.div`tw-relative tw-mt-2  sm:tw-inline-flex tw-gap-x-4`;
const Pill = tw.div`tw-inline-flex tw-text-gray-800 tw-pl-4 tw-pr-4 tw-pb-2 tw-pt-2 tw-rounded tw-font-bold tw-mt-4 tw-mr-3`;
const PillSkeleton = tw(
  Skeleton,
)`tw-inline-flex tw-rounded tw-font-bold tw-mt-4`;
const FieldHeading = tw.div`tw-font-semibold tw-text-sm`;
const FieldValue = tw.div`tw-text-xl tw-font-thin`;
const FieldContainer = tw.div`tw-relative tw-mt-3`;
const Subheading = tw.div`tw-relative tw-text-lg lg:tw-text-xl tw-font-semibold tw-inline-block`;
const HR = tw.hr`tw-mt-6 tw-mb-6`;
const SectionHeadingLeft = tw(
  SectionHeading,
)`tw-text-left tw-text-4xl tw-mt-10`;
const ChakraButton = tw(Button)`tw-bg-primary-600 tw-mt-2`;

const PillSlider = tw.div`tw-overflow-visible tw-whitespace-nowrap tw-overflow-x-scroll tw-pb-2`;

const dateDisplayOptions = {
  day: "numeric",
  month: "long",
  year: "numeric",
};

export default ({
  isAdult,
  isLoading,
  memberDetails,
  refreshMemberDetails,
}) => {
  const toast = useToast();
  const [coachLoading, setCoachLoading] = useState(false);
  const [adminLoading, setAdminLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [awardingLoading, setAwardingLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  function displayToast(title, status) {
    toast({
      title: title,
      status: status,
      isClosable: true,
    });
  }

  function makeCoach(membershipNumber, revoke) {
    setCoachLoading(true);
    const idToken = GetCookie("idToken");
    MakeCoach(idToken, membershipNumber, revoke)
      .then(() => {
        setCoachLoading(false);
        refreshMemberDetails();
        if (revoke) {
          displayToast(
            memberDetails.firstName + " is no longer a coach!",
            "success",
          );
        } else {
          displayToast(memberDetails.firstName + " is now a coach!", "success");
        }
      })
      .catch((err) => {
        //TODO - add error handling toast
        setCoachLoading(false);
        displayToast("Something went wrong - try again!", "error");
      });
  }

  function makeAdmin(membershipNumber, revoke) {
    setAdminLoading(true);
    const idToken = GetCookie("idToken");
    MakeAdmin(idToken, membershipNumber, revoke)
      .then(() => {
        setAdminLoading(false);
        refreshMemberDetails();
        if (revoke) {
          displayToast(
            memberDetails.firstName + " is no longer an admin!",
            "success",
          );
        } else {
          displayToast(
            memberDetails.firstName + " is now an admin!",
            "success",
          );
        }
      })
      .catch((err) => {
        setAdminLoading(false);
        displayToast("Something went wrong - try again!", "error");
      });
  }

  function deleteMember(membershipNumber) {
    setDeleteLoading(true);
    const idToken = GetCookie("idToken");
    DeleteMember(idToken, membershipNumber)
      .then(() => {
        window.location.href = `/membership-database?deleted=${memberDetails.firstName} ${memberDetails.familyName}`;
      })
      .catch((err) => {
        setDeleteLoading(false);
        displayToast("Something went wrong - try again!", "error");
      });
  }

  function awardMembership(membershipNumber) {
    setAwardingLoading(true);
    const idToken = GetCookie("idToken");
    AwardMembership(idToken, membershipNumber)
      .then(() => {
        setAwardingLoading(false);
        displayToast(
          memberDetails.firstName + " has been awarded a years membership!",
          "success",
        );
        refreshMemberDetails();
      })
      .catch((err) => {
        setAwardingLoading(false);
        displayToast("Something went wrong - try again!", "error");
      });
  }

  return (
    <Container>
      <ContentWithPaddingXl>
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/profile">My Account</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href="/membership-database">
              Membership Database
            </BreadcrumbLink>
          </BreadcrumbItem>
          <Skeleton isLoaded={!isLoading}>
            <BreadcrumbItem>
              <BreadcrumbLink>
                {memberDetails == null ? "" : memberDetails.firstName}{" "}
                {memberDetails == null ? "" : memberDetails.familyName}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Skeleton>
        </Breadcrumb>
        <SectionHeadingLeft>Member Details</SectionHeadingLeft>
        <PillSkeleton isLoaded={!isLoading}>
          {!isLoading && (
            <PillSlider>
              {memberDetails.membershipStatus === "ACTIVE" && (
                <Pill style={{ backgroundColor: "#c1f0b2" }}>
                  ACTIVE MEMBERSHIP
                </Pill>
              )}
              {memberDetails.membershipStatus === "EXPIRING" && (
                <Pill style={{ backgroundColor: "#f5da64" }}>
                  EXPIRING MEMBERSHIP
                </Pill>
              )}
              {memberDetails.membershipStatus === "NEW" && (
                <Pill style={{ backgroundColor: "#f0c1b2" }}>
                  NEW MEMBERSHIP
                </Pill>
              )}
              {memberDetails.membershipStatus === "EXPIRED" && (
                <Pill style={{ backgroundColor: "#f0c1b2" }}>
                  EXPIRED MEMBERSHIP
                </Pill>
              )}

              {(memberDetails.isCoach || memberDetails.isActivator) &&
                memberDetails.coachStatus === "ACTIVE" && (
                  <Pill style={{ backgroundColor: "#c1f0b2" }}>
                    ACTIVE COACH
                  </Pill>
                )}
              {(memberDetails.isCoach || memberDetails.isActivator) &&
                memberDetails.coachStatus === "EXPIRING" && (
                  <Pill style={{ backgroundColor: "#f5da64" }}>
                    EXPIRING COACH
                  </Pill>
                )}
              {(memberDetails.isCoach || memberDetails.isActivator) &&
                memberDetails.coachStatus === "NEW" && (
                  <Pill style={{ backgroundColor: "#f0c1b2" }}>NEW COACH</Pill>
                )}
              {(memberDetails.isCoach || memberDetails.isActivator) &&
                memberDetails.coachStatus === "EXPIRED" && (
                  <Pill style={{ backgroundColor: "#f0c1b2" }}>
                    EXPIRED COACH
                  </Pill>
                )}
            </PillSlider>
          )}
        </PillSkeleton>
        <HR></HR>
        <Skeleton isLoaded={!isLoading}>
          {!isLoading && (
            <>
              <MemberDetails
                memberDetails={memberDetails}
                isEditable={true}
                sectionTitle={"Basic Details"}
                setMemberDetails={() => {}}
              ></MemberDetails>
              <HR></HR>
            </>
          )}
        </Skeleton>

        {!isLoading && (memberDetails.isCoach || memberDetails.isActivator) && (
          <>
            <CoachesInfo
              memberDetails={memberDetails}
              isEditable={true}
              setMemberDetails={() => {}}
            ></CoachesInfo>

            <HR></HR>
          </>
        )}

        {!isLoading && (
          <>
            <Subheading>Further Details</Subheading>
            <ColumnInformationContainer>
              <FieldContainer>
                <FieldHeading>Membership Number</FieldHeading>
                <FieldValue>{memberDetails.membershipNumber}</FieldValue>
              </FieldContainer>
              <FieldContainer>
                <FieldHeading>Member Since</FieldHeading>
                <FieldValue>
                  {new Date(memberDetails.createdAt).toLocaleDateString(
                    "en-GB",
                    dateDisplayOptions,
                  )}
                </FieldValue>
              </FieldContainer>
              <FieldContainer>
                <FieldHeading>Membership Type</FieldHeading>
                <FieldValue>
                  {isAdult ? "Adult Member" : "Junior Member"}
                </FieldValue>
              </FieldContainer>
              <FieldContainer>
                <FieldHeading>Admin Privileges</FieldHeading>
                <FieldValue>
                  {memberDetails.isAdmin ? "Admin" : "None"}
                </FieldValue>
              </FieldContainer>
            </ColumnInformationContainer>
            <HR></HR>
            <SpondGroupContainer>
              {(memberDetails.isCoach || memberDetails.isActivator) && (
                <ChakraButton
                  leftIcon={<RevokeIcon />}
                  colorScheme="red"
                  onClick={(e) => {
                    makeCoach(memberDetails.membershipNumber, true);
                  }}
                  size="lg"
                  isLoading={coachLoading}
                  loadingText="REVOKE COACH"
                  spinnerPlacement="start"
                >
                  REVOKE COACH
                </ChakraButton>
              )}
              {!memberDetails.isCoach && !memberDetails.isActivator && (
                <ChakraButton
                  leftIcon={<AddIcon />}
                  colorScheme="red"
                  onClick={(e) => {
                    makeCoach(memberDetails.membershipNumber, false);
                  }}
                  isLoading={coachLoading}
                  loadingText="MAKE COACH"
                  size="lg"
                  spinnerPlacement="start"
                >
                  MAKE COACH
                </ChakraButton>
              )}
              {!memberDetails.isAdmin && (
                <ChakraButton
                  leftIcon={<AddIcon />}
                  isLoading={adminLoading}
                  colorScheme="red"
                  onClick={(e) => {
                    makeAdmin(memberDetails.membershipNumber, false);
                  }}
                  size="lg"
                >
                  MAKE ADMIN
                </ChakraButton>
              )}
              {memberDetails.isAdmin && (
                <ChakraButton
                  leftIcon={<RevokeIcon />}
                  colorScheme="red"
                  isLoading={adminLoading}
                  onClick={(e) => {
                    makeAdmin(memberDetails.membershipNumber, true);
                  }}
                  size="lg"
                >
                  REVOKE ADMIN
                </ChakraButton>
              )}
              <ChakraButton
                leftIcon={<AwardIcon />}
                isLoading={awardingLoading}
                colorScheme="red"
                size="lg"
                onClick={(e) => {
                  awardMembership(memberDetails.membershipNumber);
                }}
              >
                AWARD YEAR MEMBERSHIP
              </ChakraButton>
              <ChakraButton
                isLoading={deleteLoading}
                onClick={onOpen}
                loadingText="PLEASE WAIT"
                spinnerPlacement="start"
                leftIcon={<DeleteButtonIcon />}
                colorScheme="red"
                size="lg"
              >
                DELETE ACCOUNT
              </ChakraButton>
            </SpondGroupContainer>
            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Delete Member
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    Are you sure you want to delete {memberDetails.firstName}{" "}
                    {memberDetails.familyName}? This action cannot be undone.
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={() => {
                        deleteMember(memberDetails.membershipNumber);
                      }}
                      ml={3}
                    >
                      Delete
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </>
        )}
      </ContentWithPaddingXl>
    </Container>
  );
};
