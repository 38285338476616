import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-8.svg";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
import {
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/tabs";
import CoachesCards from "./CoachesCards";
import CommitteeCards from "./CommitteeCards";

const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`tw-text-center tw-mb-3`;
const Description = tw(
  SectionDescription,
)`tw-max-w-2xl tw-mx-auto tw-text-center`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`tw-pointer-events-none -tw-z-20 tw-absolute tw-right-0 tw-top-0 tw-h-56 tw-w-56 tw-opacity-15 tw-transform tw-translate-x-2/3 -tw-translate-y-12 tw-text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`tw-pointer-events-none -tw-z-20 tw-absolute tw-left-0 tw-bottom-0 tw-h-64 tw-w-64 tw-opacity-15 tw-transform -tw-translate-x-2/3 tw-text-primary-500`}
`;
const ChackraTabs = tw(Tabs)`tw-mt-12`;
const Cards = tw.div`tw-flex tw-flex-wrap tw-flex-row tw-justify-center sm:tw-max-w-2xl lg:tw-max-w-5xl tw-mx-auto`;
const Card = tw.div`tw-mt-12 tw-w-full sm:tw-w-1/2 lg:tw-w-1/3 tw-flex tw-flex-col tw-items-center`;
const CardImage = styled.div`
  ${(props) => css`
    background-image: url("${props.imageSrc}");
  `}
  ${tw`tw-w-64 tw-h-64 tw-bg-cover tw-bg-center tw-rounded`}
`;
const CardContent = styled.div`
  ${tw`tw-flex tw-flex-col tw-items-center tw-mt-6`}
  .position {
    ${tw`tw-uppercase tw-font-bold tw-tracking-widest tw-text-xs tw-text-primary-500`}
  }
  .name {
    ${tw`tw-mt-1 tw-text-xl tw-font-medium tw-text-gray-900`}
  }
`;

export default ({ heading = "", subheading = "", description = "." }) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>

        <ChackraTabs
          position="relative"
          variant="line"
          align="center"
          colorScheme="black"
          size="lg"
        >
          <TabList>
            <Tab>Coaches</Tab>
            <Tab>Committee</Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="red.500"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel>
              <Cards>
                <CoachesCards />
              </Cards>
            </TabPanel>
            <TabPanel>
              <Cards>
                <CommitteeCards />
              </Cards>
            </TabPanel>
          </TabPanels>
        </ChackraTabs>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
