import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import illustration from "images/IMG_9355.JPG";
import logo from "images/BADtri_logo_black.svg";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { SignIn } from "api/openApi.ts";

import BeatLoader from "react-spinners/BeatLoader";
import {
  ChakraButton,
  Container,
  Content,
  DontHaveAnAccountButton,
  DontHaveAnAccountText,
  Error,
  ErrorTitle,
  ForgotPasswordButton,
  ForgotPasswordText,
  Form,
  FormContainer,
  Heading,
  IllustrationContainer,
  IllustrationImage,
  Input,
  Label,
  LogoImage,
  LogoLink,
  MainContainer,
  MainContent,
  SubHeading,
} from "./Login.styles";

export default ({
  logoLinkUrl = "/",
  illustrationImageSrc = illustration,
  headingText = "Sign In",
  submitButtonText = "Sign In",
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = "password-reset",
  signupUrl = "/join",
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(true);
  const [error, setError] = useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const membershipNumber = urlParams.get("membershipNumber");

  const signIn = async () => {
    const validMembershipNumberReg = /^\d+$/;
    const typedMembershipNumber =
      document.getElementById("membershipNumber").value;
    const typedPassword = document.getElementById("password").value;

    if (typedMembershipNumber === "") {
      setError("Please supply a membership number");
      setSuccess(false);
      return;
    }
    if (!validMembershipNumberReg.test(typedMembershipNumber)) {
      setError("Membership number must be a number");
      setSuccess(false);
      return;
    }

    if (typedPassword === "") {
      setError("Please supply a password");
      setSuccess(false);
      return;
    }

    const signUpRequest = {
      membershipNumber: typedMembershipNumber,
      password: typedPassword,
    };
    setSubmitting(true);

    try {
      const response = await SignIn(signUpRequest);
      document.location.href = "/profile";
      document.cookie = "sessionToken=" + response.accessToken;
      document.cookie = "idToken=" + response.idToken;
    } catch (e) {
      setSubmitting(false);
      setSuccess(false);
      setError(
        "Your password and username did not seem to match. Please try again.",
      );
    }
  };

  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              {membershipNumber != null && (
                <SubHeading>
                  Your membership number is: {membershipNumber}
                </SubHeading>
              )}
              <FormContainer>
                <Form>
                  <Label>Username:</Label>
                  <Input
                    id="membershipNumber"
                    autocomplete="username"
                    value={membershipNumber}
                    type="username"
                    placeholder="Membership Number"
                  />
                  <Input id="password" type="password" placeholder="Password" />
                  {!success && (
                    <Error>
                      <ErrorTitle>There is a problem</ErrorTitle>
                      {error}
                    </Error>
                  )}
                  <ChakraButton
                    isLoading={submitting}
                    spinner={<BeatLoader size={8} color="white" />}
                    colorScheme="red"
                    leftIcon={<SubmitButtonIcon />}
                    onClick={signIn}
                    size="lg"
                  >
                    {submitButtonText}
                  </ChakraButton>
                </Form>
                <ForgotPasswordButton>
                  <ForgotPasswordText href={forgotPasswordUrl}>
                    Forgot Password?
                  </ForgotPasswordText>
                </ForgotPasswordButton>
                <DontHaveAnAccountButton>
                  Don't have an account?{" "}
                  <DontHaveAnAccountText href={signupUrl}>
                    Sign Up
                  </DontHaveAnAccountText>
                </DontHaveAnAccountButton>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
