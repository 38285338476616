import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings";
import { ReactComponent as SubmitButtonIcon } from "feather-icons/dist/icons/search.svg";
import { ReactComponent as WarningIcon } from "feather-icons/dist/icons/alert-triangle.svg";
import { ReactComponent as CheveronDown } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as CheveronUp } from "feather-icons/dist/icons/chevron-up.svg";

export const Table = tw.table`tw-max-h-[300px] tw-w-full tw-relative tw-border-collapse`;
export const TableHeader = tw.thead`tw-h-10 tw-bg-gray-200 tw-border-0 tw-sticky tw-top-0 tw-text-lg`;
export const TableHeaderCell = tw.th`tw-text-left tw-p-5`;
export const SecondaryTableHeaderCell = tw(
  TableHeaderCell,
)`tw-hidden md:tw-table-cell`;
export const TableRow = tw.tr`tw-border-b-[1px] tw-border-gray-600 hover:tw-bg-gray-200 tw-cursor-pointer`;
export const TableBody = tw.tbody`tw-bg-white`;
export const TableBodyCell = tw.td` tw-px-5 tw-py-3`;
export const SecondaryTableBodyCell = tw(
  TableBodyCell,
)`tw-hidden md:tw-table-cell`;
export const ColumnInformationContainer = tw.div`tw-relative md:tw-grid tw-w-full md:tw-grid-cols-2`;
export const TableSuperContainer = tw.div`tw-relative tw-w-screen lg:tw-w-full tw-bg-gray-100 tw-mt-5 -tw-ml-8 lg:tw-ml-0 tw-drop-shadow-xl tw-drop-shadow-2xl tw-mb-64`;
export const LoadingTableSuperContainer = tw.div`tw-animate-pulse tw-relative tw-w-screen lg:tw-w-full tw-bg-gray-100 tw-mt-5 -tw-ml-8 lg:tw-ml-0 tw-drop-shadow-xl tw-py-[200px] tw-h-[400px] tw-overflow-scroll tw-drop-shadow-2xl tw-rounded-2xl`;
export const FieldHeading = tw.div`tw-font-semibold tw-text-sm`;
export const FieldValue = tw.div`tw-text-xl tw-font-thin`;
export const FieldContainer = tw.div`tw-relative tw-mt-3`;
export const Pill = tw.div`tw-uppercase tw-inline tw-text-gray-800 tw-pl-4 tw-pr-4 tw-pb-1 tw-pt-1 tw-rounded tw-font-bold tw-mt-6 tw-mb-6 tw-mr-3`;
export const PillGreen = tw(Pill)`tw-bg-[#99ff99]`;
export const PillRed = tw(Pill)`tw-bg-[#ff9999]`;
export const PillYellow = tw(Pill)`tw-bg-[#ffd699]`;
export const Heading = tw(
  SectionHeading,
)`tw-w-full tw-text-left tw-mb-10 tw-mt-10`;
export const FilterOption = tw.button`tw-mr-5 tw-border-solid first:tw-border-gray-900 tw-border-gray-300 first:tw-bg-gray-100 tw-border-2 tw-pt-2 tw-pb-2 tw-pr-5 tw-pl-5 hover:tw-bg-gray-200 tw-duration-200`;
export const FilterContainer = tw.div`tw-inline-flex tw-overflow-scroll tw-max-w-full tw-pb-2`;
export const SearchInputContainer = tw.div`tw-mt-5`;
export const SearchInput = tw.input`tw-inline tw-border-2 tw-border-gray-900 tw-tracking-wide tw-font-semibold tw-px-8 tw-py-4 tw-items-center tw-justify-center focus:tw-shadow-outline focus:tw-outline-none disabled:tw-bg-gray-400 tw-w-full lg:tw-w-1/2 xl:tw-w-1/3`;
export const SearchIcon = tw(SubmitButtonIcon)`tw-inline -tw-ml-10`;
export const HR = tw.hr`tw-mt-6 tw-mb-6`;
export const ActionContainerHead = tw.div`tw-w-full tw-bg-red-800 tw-drop-shadow-xl tw-px-5 tw-mb-2 tw-text-gray-100`;
export const ActionCentreContainer = tw.div`tw-relative tw-w-full tw-mb-16`;
export const ActionContainerRed = tw.div`tw-w-full tw-bg-red-100 tw-drop-shadow-xl tw-px-5 tw-border-l-2 tw-border-primary-600 tw-mb-2`;
export const ActionContainerAmber = tw.div`tw-w-full tw-bg-red-100 tw-drop-shadow-xl tw-px-5 tw-border-l-2 tw-border-yellow-600 tw-mb-2`;
export const AlertIconRed = tw(
  WarningIcon,
)`tw-relative tw-text-red-600 tw-inline tw-mb-auto tw-mt-auto`;
export const AlertIconAmber = tw(
  WarningIcon,
)`tw-relative tw-text-yellow-600 tw-inline tw-mb-auto tw-mt-auto`;
export const AlertIconWhite = tw(
  WarningIcon,
)`tw-relative tw-text-gray-100 tw-inline tw-mb-auto tw-mt-auto`;
export const CheveronDownButton = tw(CheveronDown)``;
export const CheveronUpButton = tw(CheveronUp)`tw-hidden`;
export const AlertText = tw.div`tw-inline-block tw-font-semibold tw-p-5`;

export const TD = tw.td`tw-align-middle`;
export const TD100 = tw.td`tw-w-full tw-align-middle`;
