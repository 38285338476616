import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import illustration from "images/IMG_9355.JPG";
import logo from "images/BADtri_logo_black.svg";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { ForgotPassword, SetNewPassword } from "api/openApi.ts";
import {
  Button,
  InputGroup,
  InputRightElement,
  Input,
  PinInput,
  PinInputField,
  useToast,
  FormHelperText,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import BeatLoader from "react-spinners/BeatLoader";
import { ReactComponent as VerifyIcon } from "feather-icons/dist/icons/shield.svg";
import {
  ChakraButton,
  ChakraHStack,
  ChakraInput,
  Container,
  Content,
  Error,
  ErrorTitle,
  Form,
  FormContainer,
  Heading,
  IllustrationContainer,
  IllustrationImage,
  Label,
  LogoImage,
  LogoLink,
  MainContainer,
  MainContent,
  SubHeading,
} from "./PasswordReset.styles";

export default ({
  logoLinkUrl = "/",
  illustrationImageSrc = illustration,
  headingText = "Password Reset",
  SubmitButtonIcon = LoginIcon,
}) => {
  const toast = useToast();
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [submittingForgotPassword, setSubmittingForgotPassword] =
    useState(false);
  const [submittingVerify, setSubmittingVerify] = useState(false);
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(true);
  const [error, setError] = useState("");
  const [step, setStep] = useState(1);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verificationCode, setVerificationCode] = React.useState("");
  const [passwordInvlaid, setPasswordInvlaid] = React.useState(false);

  const verifyNewPassword = function () {
    setSubmittingVerify(true);

    SetNewPassword({
      membershipNumber: username,
      newPassword: password,
      confirmationCode: verificationCode,
    })
      .then(() => {
        setSubmittingVerify(false);
        window.location.href = "/login";
      })
      .catch(() => {
        setSubmittingVerify(false);
        toast({
          title: "Hmm not quite try again...",
          status: "error",
          isClosable: true,
        });
      });
  };

  const resetPassword = function () {
    if (password.length < 8) {
      setPasswordInvlaid(true);
      return;
    }

    setSubmittingForgotPassword(true);

    ForgotPassword({
      membershipNumber: username,
    })
      .then(() => {
        setSubmittingForgotPassword(false);
        setStep(2);
      })
      .catch(() => {
        setForgotPasswordSuccess(false);
        setSubmittingForgotPassword(false);
        setError("Something went wrong");
      });
  };

  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              {step === 1 && (
                <SubHeading>
                  Please provide your membership number and new password. To
                  verify it's you we'll then send you a verification email.
                </SubHeading>
              )}
              {step === 2 && (
                <SubHeading>
                  If you provided the correct membership number we've now sent
                  you an email with a 6 digit verification code. Please enter it
                  below.
                </SubHeading>
              )}
              <FormContainer>
                <Form>
                  {step === 1 && (
                    <>
                      <Label>Username:</Label>
                      <ChakraInput
                        onChange={(e) => {
                          setUsername(e.target.value);
                        }}
                        size="md"
                        id="membershipNumber"
                        autocomplete="username"
                        type="username"
                        placeholder="Membership Number"
                      />

                      <InputGroup>
                        <FormControl isInvalid={passwordInvlaid}>
                          <Input
                            size="md"
                            pr="4.5rem"
                            type={show ? "text" : "password"}
                            placeholder="Enter new password"
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                          />
                          <InputRightElement width="4.5rem">
                            <Button h="1.75rem" size="sm" onClick={handleClick}>
                              {show ? "Hide" : "Show"}
                            </Button>
                          </InputRightElement>
                          {!passwordInvlaid && (
                            <FormHelperText>
                              Minimum 8 characters
                            </FormHelperText>
                          )}
                          <FormErrorMessage>
                            Your password needs to be a minimum of 8 characters
                          </FormErrorMessage>
                        </FormControl>
                      </InputGroup>
                      {!forgotPasswordSuccess && (
                        <Error>
                          <ErrorTitle>There is a problem</ErrorTitle>
                          {error}
                        </Error>
                      )}
                      <ChakraButton
                        isLoading={submittingForgotPassword}
                        spinner={<BeatLoader size={8} color="white" />}
                        colorScheme="red"
                        leftIcon={<SubmitButtonIcon />}
                        onClick={resetPassword}
                        size="md"
                      >
                        Reset Password
                      </ChakraButton>
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <ChakraHStack>
                        <PinInput
                          onChange={(e) => {
                            setVerificationCode(e);
                          }}
                        >
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                        </PinInput>
                      </ChakraHStack>
                      <ChakraButton
                        isLoading={submittingVerify}
                        spinner={<BeatLoader size={8} color="white" />}
                        colorScheme="red"
                        leftIcon={<VerifyIcon />}
                        onClick={verifyNewPassword}
                        size="md"
                      >
                        Verify
                      </ChakraButton>
                    </>
                  )}
                </Form>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
